import GenericAction from '@/components/actions/GenericAction.jsx'
import { ImagePlus } from 'lucide-react'
import AActionPrompt from '@/components/actions/components/AActionPrompt.tsx'
import { ITERATION_STATUSES } from '@/const/const.js'
import MImagePreview from '../molecules/actions/MImagePreview.tsx'
import AActionContentFiles from './components/AActionContentFiles.tsx'

export default function GenerateImageAction({
  action,
  isLast = false,
}: {
  action: GenerateImageActionPayload
  isLast?: boolean
}) {
  const imagePrompt = action?.args?.text || action?.args?.prompt

  const isDone = action.status === ITERATION_STATUSES.DONE

  const successOutput = action.outputs.find(isSuccessOutput)

  return (
    <GenericAction
      action={action}
      detailsContent={JSON.stringify(action, null, 2)}
      actionIcon={ImagePlus}
      isLast={isLast}
      title={action.args.comment ?? 'Generate image'}
    >
      {isDone && successOutput && successOutput.public_url_small && (
        <MImagePreview
          src={successOutput.public_url_small}
          alt={action.args.comment}
          fileName={successOutput.image_path}
          downloadUrl={successOutput.public_url_original}
          className="mb-2"
        />
      )}
      <AActionContentFiles content={action.args.full_file_name_and_path} />
      <AActionPrompt className="mt-1.5" content={imagePrompt} />
    </GenericAction>
  )
}

// write typescript type for theAction
export type GenerateImageActionPayload = {
  actionId: string
  args: {
    full_file_name_and_path: string
    size: string
    comment: string
    prompt: string
  }
  elementId: string
  stepId: string
  startSnapshotHash: any
  name: string
  obsolete: boolean
  subtype: any
  index: number
  iterationId: string
  hideArgsFromPrompt: boolean
  id: string
  endSnapshotHash: any
  type: 'function'
  startCommitHash: string
  endCommitHash: string
  status: string
  outputs: GenerateImageActionOutput[]
  createdAt: {
    _seconds: number
    _nanoseconds: number
  }
  endedAt: number
  startedAt: number
  elapsedTime: number
  updatedAt: {
    _seconds: number
    _nanoseconds: number
  }
  elementIndex: number
  stepIndex: number
  repoURI?: string
}

type FailedOutput = {
  success: false
  message: string
  image_path: null
  public_url_large?: null
  public_url_small?: null
  public_url_original?: null
}

type SuccessOutput = {
  success: true
  message: string
  image_path: string
  public_url_large: string
  public_url_small: string
  public_url_original: string
}

type GenerateImageActionOutput = FailedOutput | SuccessOutput

function isSuccessOutput(output: GenerateImageActionOutput): output is SuccessOutput {
  return output.success
}
