import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { Wand } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import { MarkdownContent } from '@/components/actions/components/MarkdownContent'

export default function DefaultAction({ action, isLast = false }) {
  let detailsContent = ''
  detailsContent += `## Inputs\n`
  const args = action?.args
  detailsContent += prettyPrintField(args)

  const outputs = action?.outputs

  if (outputs) {
    detailsContent += `## Outputs:\n`
    outputs.forEach(output => {
      detailsContent += prettyPrintField(output)
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={Wand}
      isLast={isLast}
      title={action.args?.comment || convertString(action.name)}
    >
      <AActionContentGenericLayout>
        <MarkdownContent detailsContent={detailsContent} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

DefaultAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}

function convertString(input) {
  // Replace underscores with spaces
  let converted = input.replace(/_/g, ' ')

  // Capitalize the first letter
  converted = converted.charAt(0).toUpperCase() + converted.slice(1)

  return converted
}

function prettyPrintField(field: any) {
  let content = ''
  if (field == null) {
    content += `NULL\n`
  } else if (typeof field === 'string') {
    content += `* ${field}\n`
  } else if (typeof field === 'object') {
    Object.entries(field).forEach(([key, value]) => {
      const prettyKey = convertString(key)
      if (Array.isArray(value)) {
        const jsonedVal = JSON.stringify(value, null, 2)
        content += `* ${prettyKey}:\n` + '```json\n' + jsonedVal + '\n```'
      } else if (value === null) {
        content += `* ${prettyKey}: NULL\n`
      } else if (typeof value === 'object') {
        const jsonedVal = JSON.stringify(value, null, 2)
        content += `* ${prettyKey}:\n` + '```json\n' + jsonedVal + '\n' + '```\n'
      } else if (typeof value === 'string') {
        content += `* ${prettyKey}: ${value}\n`
      }
    })
  }

  return content
}
