import { cn } from '@/lib/utils'
import React from 'react'

interface FormErrorProps {
  error?: {
    message?: string
  }
  className?: string
}

export const FormErrorField: React.FC<FormErrorProps> = ({ error, className }) => {
  if (!error?.message) return null

  return <p className={cn('mt-1 text-sm text-red-500', className)}>{error.message}</p>
}
