import { cn } from '@/lib/utils.ts'
import { CloudDownload, Info } from 'lucide-react'
import React from 'react'

export default function MImagePrevew({
  src,
  alt,
  fileName,
  downloadUrl,
  className,
  children,
}: {
  src: string
  alt: string
  fileName: string
  downloadUrl: string
  className?: string
  children?: React.ReactNode
}) {
  const [isShowingInfo, setIsShowingInfo] = React.useState(false)

  return (
    <figure
      className={cn(
        'mt-4 overflow-hidden rounded-md border border-base-border text-base-foreground',
        className
      )}
    >
      <img className="mx-auto" src={src} alt={alt} />
      <figcaption
        className={cn(
          'flex items-center justify-between',
          'text-sm',
          'bg-base-muted',
          'min-h-10 px-4 py-2.5',
          'rounded-b-md',
          'relative'
        )}
      >
        <span className="mr-auto min-w-0 overflow-clip text-ellipsis text-nowrap font-medium tracking-tight">
          {fileName}
        </span>
        {children && (
          <>
            <div
              className={cn(
                'absolute inset-x-0 bottom-full w-full transition-opacity',
                !isShowingInfo && 'pointer-events-none opacity-0'
              )}
            >
              {children}
            </div>
            <button onClick={() => setIsShowingInfo(!isShowingInfo)}>
              <Info className="size-4" />
            </button>
          </>
        )}
        <a className="ml-2" href={downloadUrl} target="_blank" rel="noreferrer" download>
          <CloudDownload className="size-4" />
        </a>
      </figcaption>
    </figure>
  )
}
