import { browserLocalPersistence, initializeAuth } from 'firebase/auth'
import { collection, getFirestore } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import CONFIG from '@/config/config'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { GetIterationInput, GetIterationOutput } from './firebase_shared_types'

export const COLLECTIONS = {
  users: 'users',
  userRoles: 'userRoles',
  organizations: 'organizations',
  teams: 'teams',
  projects: 'projects',
  projectsViewableIterations: 'viewableIterations',
  projectsViewableIterationsMeta: 'viewableIterationsMeta',
  hints: 'hints',
  jobs: 'gunslingerJobs',
  iterationFeedback: 'iterationFeedback',
  configurationTemplates: 'configurationTemplates',
}

export const firebaseApp = initializeApp(CONFIG.firebase)
export const firestoreDb = getFirestore(firebaseApp)
export const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
})

export const functions = getFunctions(firebaseApp)

// Connect to emulators if running locally
// if (window.location.hostname === 'localhost') {
//   connectAuthEmulator(auth, 'http://localhost:9099')
//   connectFirestoreEmulator(firestoreDb, 'localhost', 8080)
//   connectFunctionsEmulator(functions, 'localhost', 5001)
//   const database = getDatabase(firebaseApp)
//   connectDatabaseEmulator(database, 'localhost', 9000)
//   const storage = getStorage(firebaseApp)
//   connectStorageEmulator(storage, 'localhost', 9199)
// }

export const getConfigurationTemplatesFirebaseFunction = httpsCallable(
  functions,
  'getConfigurationTemplates'
)
export const onboardUserFirebaseFunction = httpsCallable(functions, 'onboardUser')
export const doesUserExistFirebaseFunction = httpsCallable<
  { email: string },
  { userExists: boolean }
>(functions, 'doesUserExist')
export const createIterationForProject = httpsCallable(functions, 'createIterationForProject')
export const createOrganization = httpsCallable(functions, 'createOrganization')
export const updateOrganization = httpsCallable(functions, 'updateOrganization')
export const createTeam = httpsCallable(functions, 'createTeam')
export const updateTeam = httpsCallable(functions, 'updateTeam')
export const createTemplate = httpsCallable(functions, 'createTemplate')

export const updateTemplate = httpsCallable(functions, 'updateTemplate')
export const deleteTemplate = httpsCallable(functions, 'deleteTemplate')
export const getIteration = httpsCallable<GetIterationInput, GetIterationOutput>(
  functions,
  'getIteration'
)
export const getIterationDefault = httpsCallable(functions, 'getIterationDefault')
export const getHintsForProject = httpsCallable(functions, 'getHintsForProject')
export const updateHintFirebaseFunction = httpsCallable(functions, 'updateHint')
export const createHintFirebaseFunction = httpsCallable(functions, 'createHint')
export const deleteHintFirebaseFunction = httpsCallable(functions, 'deleteHint')

export const createIterationCommandFirebaseFunction = httpsCallable(
  functions,
  'createIterationCommand'
)

export const removeAllIterationCommandsFirebaseFunction = httpsCallable(
  functions,
  'removeAllIterationCommands'
)

export const stopVMJobForIterationFirebaseFunction = httpsCallable(
  functions,
  'stopVMJobForIteration'
)
export const createIterationFeedbackFirebaseFunction = httpsCallable(
  functions,
  'createIterationFeedback'
)
export const createProjectFirebaseFunction = httpsCallable(functions, 'createProject')
export const updateProjectFirebaseFunction = httpsCallable(functions, 'updateProject')

export const getLLMActionsForIterationElementStepFirebaseFunction = httpsCallable(
  functions,
  'getLLMActionsForIterationElementStep'
)

export const authorizeGithub = httpsCallable<never, { installationUrl: string }>(
  functions,
  'authorizeGithub'
)
export const installGithub = httpsCallable<never, { installationUrl: string }>(
  functions,
  'installGithub'
)

export const sendMagicLink = httpsCallable<{ email: string; devDomain?: string }, unknown>(
  functions,
  'sendMagicLink'
)

export const fetchGithubRepositories = httpsCallable<
  { login: string },
  {
    id: string
    name: string
    full_name: string
    url: string
    clone_url: string
    default_branch: string
    owner: { login: string; id: number; avatar_url: string }
  }[]
>(functions, 'fetchGithubRepositories')

export const usersCollectionRef = collection(firestoreDb, COLLECTIONS.users)
export const userRolesCollectionRef = collection(firestoreDb, COLLECTIONS.userRoles)
export const organizationsCollectionRef = collection(firestoreDb, COLLECTIONS.organizations)
export const teamsCollectionRef = collection(firestoreDb, COLLECTIONS.teams)
export const projectsCollectionRef = collection(firestoreDb, COLLECTIONS.projects)

export const hintsCollectionRef = collection(firestoreDb, COLLECTIONS.hints)
export const jobsCollectionRef = collection(firestoreDb, COLLECTIONS.jobs)
export const iterationFeedbackCollectionRef = collection(firestoreDb, COLLECTIONS.iterationFeedback)

export const createAndInviteUserFirebaseFunction = httpsCallable<
  {
    firstName: string
    lastName: string
    email: string
    organizationId: string
    isExternal: boolean
    isOnboarding: boolean
    projectToOnboard?: string
  },
  {
    success: boolean
    userId: string
  }
>(functions, 'createAndInviteUser')
