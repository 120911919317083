import { useToast } from '@/components/ui/use-toast.js'
import { ITERATION_COMMANDS } from '@/const/const.ts'
import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics.js'
import { useCallback } from 'react'
import { useSendUserCommand } from '../sendUserCommand.ts'
import { useIterationActiveRole } from './useIterationActiveRole.ts'

export function usePromptMutation(iterationId: string) {
  const [_rolename, role] = useIterationActiveRole(iterationId)
  const { toast } = useToast()
  const sendCommand = useSendUserCommand(iterationId)

  const callSendCommand = useCallback(
    async (prompt: string) => {
      sendCommand.mutate(
        role?.status === 'waiting_for_message'
          ? {
              command: ITERATION_COMMANDS.ROLE_MESSAGE,
              commandArgs: {
                role: 'technicalanalyst',
                content: {
                  choice: 'update',
                  message: prompt,
                },
              },
            }
          : {
              command: ITERATION_COMMANDS.EXTEND,
              commandArgs: {
                iteration_id: iterationId,
                prompt,
              },
            },
        {
          onError(error) {
            console.error('Error creating continuation prompt', error)
            toast({
              variant: 'destructive',
              title: 'Error extending iteration 😔',
              description: 'Try refreshing the page or contact Proofs team.',
            })
          },
          onSuccess() {
            analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
              iterationId: iterationId,
              prompt: prompt,
            })
          },
        }
      )
    },
    [sendCommand, toast, iterationId, role?.status]
  )

  const validate = useCallback(async () => {
    sendCommand.mutate(
      {
        command: ITERATION_COMMANDS.ROLE_MESSAGE,
        commandArgs: {
          role: 'technicalanalyst',
          content: {
            choice: 'validate',
            message: '',
          },
        },
      },
      {
        onError(error) {
          console.error('Error validating iteration plan', error)
          toast({
            variant: 'destructive',
            title: 'Error extending iteration 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        },
        onSuccess() {
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXTEND, {
            iterationId: iterationId,
            prompt: prompt,
          })
        },
      }
    )
  }, [sendCommand, toast, iterationId])

  return {
    mutation: sendCommand,
    mutate: callSendCommand,
    validate,
    isLoading: sendCommand.isPending,
  }
}
