import {
  GUNSLINGER_JOB_STATUSES,
  GunslingerJobStatus,
  IterationComputedStatus,
  IterationStatus,
} from '@/const/const.ts'

const VM_OVERRIDE_STATUSES = [
  GUNSLINGER_JOB_STATUSES.STOPPED,
  GUNSLINGER_JOB_STATUSES.STOPPING,
  GUNSLINGER_JOB_STATUSES.ERROR,
] as const

export function getIterationComputedStatus(
  iterationStatus: IterationStatus | null,
  vmStatus: GunslingerJobStatus | null | undefined
): IterationComputedStatus | null {
  if (vmStatus && VM_OVERRIDE_STATUSES.includes(vmStatus)) {
    return vmStatus
  }

  return iterationStatus
}

export function formatIterationStatus(status: IterationComputedStatus): string {
  if (!status) {
    return ''
  }
  return status.replace(/_/g, ' ')
}
