export const WIZARD_STEPS = ['project', 'repository', 'environment', 'summary'] as const

export const helpContentWithExamples = {
  default: {
    title: 'Help Guide',
    text: 'Please enter the details of the project you want to create.',
    examples: [],
  },
  name: {
    title: 'Project Name',
    text: 'Enter the name of the project.',
    examples: [],
  },
  organizationId: {
    title: 'Organization',
    text: 'Please select the organization to which the project should belong.',
    examples: [],
  },
  teamId: {
    title: 'Team',
    text: 'Please select the team to which the project should belong.',
    examples: [],
  },
  prospectName: {
    title: 'Prospect',
    text: "Enter the name of the prospect or company you're creating this project for.",
    examples: [],
  },
  prospectWebsite: {
    title: 'Website',
    text: 'Enter the website URL of the prospect. This helps in understanding their current online presence.',
    examples: [],
  },
  techStack: {
    title: 'Stack',
    text: 'Tech stack used to build this Proof of Concept.',
    examples: ['React, Node.js, MongoDB', 'Vue.js, Express, PostgreSQL', 'Angular, Django, MySQL'],
  },
  githubAccount: {
    title: 'Repository',
    text: 'Select your GitHub account.',
    examples: [],
  },
  repoURI: {
    title: 'Repository',
    text: 'Provide your repository Uniform Resource Identifier (URI).',
    examples: [],
  },
  startCommitHash: {
    title: 'Start Commit Hash',
    text: 'Commit hash where Proofs will start building PoC.',
    examples: [],
  },
}
