import { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { InformationCircleIcon } from '@heroicons/react/24/solid/index.js'
import { Button } from '@/components/catalyst/button.jsx'

import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics'
import { useSearchParams } from 'react-router-dom'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import PropTypes from 'prop-types'
import { ACTION_STATUSES, ELEMENT_STATUSES } from '@/const/const'
import {
  GitCompare,
  GitCompareIcon,
  InfoIcon,
  MoreVertical,
  SquarePlus,
  XOctagon,
} from 'lucide-react'
import Logo from '@/assets/svg-components/Logo.jsx'
import colors from 'tailwindcss/colors.js'
import { safeParseInt } from '@/lib/params-utils.js'
import { Button as ShadCNButton } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { convertGitHubSSHToHTTPS, hasValidCommitHashes } from '@/lib/git-utils.js'
import { Link } from '@/components/catalyst/link.jsx'
import MRestartFromAction from '@/components/molecules/iteration-details/MRestartFromAction.jsx'
import MActionDetailsInspector from '@/components/molecules/iteration-details/MActionDetailsInspector.jsx'
import { useIsUserSuperAdmin } from '@/stores/useStore.js'
import { cn } from '@/lib/utils'

export default function GenericAction({
  action,
  title,
  detailsContent,
  actionIcon,
  shouldBeExpanded = false,
  customOptions = {},
  isLast = false,
  children,
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showRestore, setShowRestore] = useState(false)
  const selectedActionIndex = safeParseInt(searchParams.get('action'))
  const [isJSONViewOpen, setIsJSONViewOpen] = useState(false)
  const [isLLMPlaygrounOpen, setIsLLMPlaygrounOpen] = useState(false)
  const [isActionExpanded, setIsActionExpanded] = useState(
    selectedActionIndex === action?.index || shouldBeExpanded
  )
  const isUserSuperAdmin = useIsUserSuperAdmin()

  const actionDataToDisplayOnHover = useMemo(() => {
    return _.omit(action, [
      // 'actionId',
      // 'elementId',
      // 'stepId',
      // 'iterationId',
      'projectId',
      'index',
      'status',
      'updatedAt',
      'createdAt',
      'startedAt',
      'endedAt',
      'updatedAtRelative',
      'shouldBeDeleted',
    ])
  }, [action])

  const shouldAllowLLMPlayground = useMemo(() => {
    return action?.type === 'llm' && action?.subtype === 'request'
  }, [action])

  const ActionIcon = useMemo(() => {
    return actionIcon ?? InformationCircleIcon
  }, [actionIcon])

  const handleShowDetails = useCallback(() => {
    if (!isJSONViewOpen) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ACTION_DETAILS, {
        iterationId: action?.iterationId,
        elementIndex: action?.elementIndex,
        stepIndex: action?.stepIndex,
        actionIndex: action?.index,
        actionType: action?.type,
        actionName: action?.name,
        status: action?.status,
      })
    }
    setIsJSONViewOpen(true)
  }, [isJSONViewOpen, action])

  const handleDeepLinkAction = () => {
    setSearchParams(
      prevParams => {
        const newParams = new URLSearchParams(prevParams)
        if (action?.index) {
          newParams.set('action', action.index)
        } else {
          newParams.delete('action')
        }
        newParams.set('element', action.elementIndex)
        newParams.set('step', action.stepIndex)
        return newParams
      },
      { replace: false }
    )
  }

  const [actionStatusIcon, actionTextColor, actionStatusIconBg] = useMemo(() => {
    switch (action?.status) {
      case ACTION_STATUSES.AWAITING:
        return [<ActionIcon className="text-stone-400" />, 'stone-500', 'stone-100']
      case ACTION_STATUSES.RUNNING:
        return [
          <Logo key="step-running-logo" color={colors.violet[500]} className="size-6" />,
          'stone-900',
          'violet-50',
        ]
      case ACTION_STATUSES.DONE:
        return [<ActionIcon className="text-green-600" />, 'stone-500', 'green-100']
      case ACTION_STATUSES.FAILED:
        return [<XOctagon className="size-6 text-white" />, 'red-600', 'red-500']
      default:
        return [<ActionIcon className="text-stone-400" />, 'stone-500', 'stone-50']
    }
  }, [action?.status])

  const handleToggleExpandAction = isExpanded => {
    if (isExpanded) {
      handleDeepLinkAction()
    }
    setIsActionExpanded(isExpanded)
  }

  const showCodeChangesForIteration = hasValidCommitHashes(action)
  const showActionMenu = isUserSuperAdmin || showCodeChangesForIteration

  const actionsMenu = (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ShadCNButton variant="ghost" className="size-8 p-0">
          <MoreVertical className="size-4" />
        </ShadCNButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {showCodeChangesForIteration && (
          <DropdownMenuItem>
            <Link
              href={`${convertGitHubSSHToHTTPS(action?.repoURI)}/compare/${action?.startCommitHash}...${action?.endCommitHash}`}
              target="_blank"
              className="flex items-center"
              onClick={() => {
                analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO_CHANGES_STEP_ACTION, {
                  iterationId: action?.iterationId,
                  elementIndex: action?.elementIndex,
                  stepIndex: action?.stepIndex,
                  actionIndex: action?.index,
                  actionType: action.type,
                  actionName: action.name,
                  status: action?.status,
                })
              }}
            >
              <GitCompare className="mr-2 size-4" />
              <span>Code changes</span>
            </Link>
          </DropdownMenuItem>
        )}
        {isUserSuperAdmin && (
          <DropdownMenuItem>
            <div className="flex cursor-pointer items-center " onClick={handleShowDetails}>
              <InfoIcon strokeWidth={3} className="mr-2  h-6 w-4 text-zinc-600" />
              <span>Show details</span>
            </div>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )

  return (
    <div className="flex w-full flex-col">
      <MRestartFromAction
        action={action}
        showUserInput={showRestore}
        setShowUserInput={setShowRestore}
      />
      <Collapsible
        open={isActionExpanded}
        onOpenChange={handleToggleExpandAction}
        className="w-full space-y-2"
        data-index={action?.index}
      >
        <div className="flex min-w-0 items-start justify-between gap-4">
          <div
            className={`flex size-10 shrink-0 items-center justify-center rounded-md bg-${actionStatusIconBg}`}
          >
            {actionStatusIcon}
          </div>
          <div className="flex w-full min-w-0 flex-col items-start justify-between gap-4">
            <div className="flex w-full  justify-between">
              <div className=" flex grow items-start items-center justify-start gap-4">
                <div
                  className={`text-md max-w-[500px] shrink grow basis-0 font-['Inter'] leading-tight text-${actionTextColor} ${!isActionExpanded && 'min-w-0 truncate'}`}
                >
                  {title ?? action.name}
                </div>
                <div className="mt-1 font-['Inter'] text-xs leading-none text-stone-500">
                  {getActionDuration(action)}
                </div>
              </div>
              <div className="ml-2 flex h-8 items-center justify-center gap-2 rounded-md bg-white/0">
                {showActionMenu && actionsMenu}

                {shouldBeExpanded ? (
                  <span className="w-9" />
                ) : (
                  <CollapsibleTrigger asChild>
                    <Button plain>
                      {isActionExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </Button>
                  </CollapsibleTrigger>
                )}
              </div>
            </div>
            <CollapsibleContent className="w-full space-y-2">{children}</CollapsibleContent>
          </div>
        </div>
      </Collapsible>
      {isJSONViewOpen && (
        <MActionDetailsInspector
          title="Action details"
          content={children}
          isOpen={isJSONViewOpen}
          setIsOpen={setIsJSONViewOpen}
          code={detailsContent}
          language="markdown"
          actionObject={actionDataToDisplayOnHover}
        />
      )}
    </div>
  )
}

GenericAction.propTypes = {
  action: actionShape,
  title: PropTypes.string,
  detailsContent: PropTypes.string,
  actionIcon: PropTypes.elementType,
  customOptions: PropTypes.shape({
    iconStyle: PropTypes.string,
    actionBodyStyle: PropTypes.string,
  }),
  isLast: PropTypes.bool,
  children: PropTypes.node,
}

function getActionDuration(action) {
  if (!action?.elapsedTime) {
    return ''
  }
  const elapsedTime = _.round(action.elapsedTime, action.elapsedTime >= 10 ? 0 : 1)
  return `${elapsedTime} s`
}
