import { useCallback, useState, useRef, useEffect } from 'react'
import { useToast } from '@/components/ui/use-toast'
import { Input } from '@/components/catalyst/input.jsx'
import { ITERATION_COMMANDS, ITERATION_STATUSES } from '@/const/const'

import { Button } from '@/components/ui/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { cn } from '@/lib/utils'
import { Check, MoveLeft, Plus, SquareArrowRight, TextSearch } from 'lucide-react'
import useStore, { useIsUserSuperAdmin } from '@/stores/useStore'
import { Textarea } from '@/components/ui/textarea'
import colors from 'tailwindcss/colors'
import Logo from '@/assets/svg-components/Logo.jsx'
import SpecificationPreview from './SpecificationPreview.tsx'
import { useIterationActiveRole } from '@/lib/iterations/useIterationActiveRole.ts'
import { usePromptMutation } from '@/lib/iterations/usePromptMutation.ts'
import { useIterationQuery } from '@/lib/iterations/useIterationQuery.ts'

type Extension = {
  id: string
  title: string
  prompt: string
  placeholder: string
  blueprintId?: string
}

type CustomExtensionOption = { type: 'custom' }
type BaseExtensionOption = { type: 'extension'; extension: Extension }

type ExtensionOption = BaseExtensionOption | CustomExtensionOption

const usePromptFlow = (iterationId: string) => {
  const [isApproveStep, setIsApproveStep] = useState(false)
  const [isLoadingUserCommand, setIsLoadingUserCommand] = useState(false)

  const { data: iteration } = useIterationQuery(iterationId)
  const [_rolename, role] = useIterationActiveRole(iterationId)

  const userCommandMutation = usePromptMutation(iterationId)

  const roleStatus = role?.status

  useEffect(() => {
    if (roleStatus === 'waiting_for_message') {
      setIsApproveStep(true)
    }
    if (roleStatus === 'done') {
      setIsApproveStep(false)
    }
  }, [roleStatus, iteration?.specifications])

  // Track when the the user sends a command and waits for
  // the result, i.e. updated specifications
  const lastSpecs = useRef<string | null>()
  useEffect(() => {
    if (userCommandMutation.isLoading) {
      // user initialized a command
      setIsLoadingUserCommand(true)
      lastSpecs.current = iteration?.specifications
    } else if (lastSpecs.current !== iteration?.specifications) {
      // we got back new specifications
      setIsLoadingUserCommand(false)
      lastSpecs.current = iteration?.specifications
    }
  }, [
    userCommandMutation.isLoading,
    setIsLoadingUserCommand,
    iteration?.specifications,
    roleStatus,
  ])

  return {
    isApproveStep,
    userCommandMutation,
    backToEdit: () => setIsApproveStep(false),
    role,
    isLoadingUserCommand: isLoadingUserCommand,
  }
}

export default function MContinuationChat({
  iterationId,
  className,
  iterationUsecaseId,
  specifications,
  onFinished,
}: {
  iterationId: string
  className?: string | undefined
  iterationUsecaseId: string
  command: (typeof ITERATION_COMMANDS)[keyof typeof ITERATION_COMMANDS]
  specifications: string | null
  onFinished: () => void
}) {
  const { getConfigurationTemplateByUsecaseId } = useStore()

  const configurationTemplate = getConfigurationTemplateByUsecaseId(iterationUsecaseId)
  const extensions = configurationTemplate?.iterationDefaultsTemplate?.usecase?.extensions
  const [selectedOption, setSelectedOption] = useState<ExtensionOption | null>(() => {
    if (!extensions || extensions?.length === 0) {
      return { type: 'custom' }
    }
    return null
  })

  const { backToEdit, isApproveStep, userCommandMutation, isLoadingUserCommand, role } =
    usePromptFlow(iterationId)

  const isUserSuperAdmin = useIsUserSuperAdmin()
  const [isOpen, setIsOpen] = useState(false)

  // don't show initial specifications if the active role is "done".
  // This is because we could be (wrongly) showing specifications from the last run.
  const [initialSpecifications] = useState(() => (role?.status === 'done' ? specifications : null))

  const { toast } = useToast()

  const handleCustomClick = useCallback(() => {
    setSelectedOption({ type: 'custom' })
  }, [])

  const handleExtensionOptionClick = useCallback(extension => {
    setSelectedOption({ type: 'extension', extension })
  }, [])

  const handleBack = useCallback(() => {
    setSelectedOption(null)
    if (!extensions || extensions.length === 0) {
      setIsOpen(false)
    }
  }, [extensions])

  const handleOpenChange = useCallback(
    (open: boolean) => {
      setIsOpen(open)
      if (open && (!extensions || extensions.length === 0)) {
        setSelectedOption({ type: 'custom' })
      }
    },
    [extensions]
  )

  const [promptHistory, setPromptHistory] = useState<string[]>([])
  const handleSubmitPrompt = useCallback(
    async ({ prompt, blueprintId }: { prompt: string; blueprintId?: string }) => {
      if (!prompt || prompt?.length === 0 || !iterationId) {
        toast({
          variant: 'destructive',
          title: 'We need instructions!',
          description: 'Please provide instructions on how to continue',
        })
        return
      }

      userCommandMutation.mutate(prompt)
      setPromptHistory([...promptHistory, prompt])
    },
    [iterationId, userCommandMutation, toast, promptHistory]
  )

  const handleValidate = useCallback(() => {
    userCommandMutation.validate()
    onFinished()
  }, [userCommandMutation])

  const isLoading = userCommandMutation.isLoading
  const isInitialCommand = !isLoading && promptHistory.length === 0

  return (
    <div
      className={cn(
        'cursor-pointer overflow-hidden rounded-lg outline outline-1 -outline-offset-1 outline-border',
        !isOpen && 'shadow-sm',
        className
      )}
    >
      <Collapsible open={isOpen} onOpenChange={handleOpenChange}>
        <CollapsibleTrigger asChild>
          <div
            className={cn(
              'inline-flex w-full items-center justify-between rounded-lg border border-border bg-white p-1 pr-6',
              isOpen && 'mb-1 shadow-sm'
            )}
          >
            <div className="flex items-center justify-start gap-6">
              <div className="grid size-16 place-items-center rounded-md bg-plum-50 text-plum-600">
                {isLoadingUserCommand ? (
                  <Logo className="size-5" color="currentColor" />
                ) : isInitialCommand ? (
                  <SquareArrowRight className="size-5" />
                ) : (
                  <TextSearch className="size-5" />
                )}
              </div>
              <div className="font-medium tracking-tighter text-foreground">
                {isLoadingUserCommand
                  ? 'Working on a solution'
                  : isApproveStep
                    ? 'Review final solution'
                    : 'Continue project'}
              </div>
            </div>
            <div className="text-sm text-muted-foreground">
              Follow-up with blueprint or a custom request
            </div>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          {specifications && initialSpecifications !== specifications && (
            <SpecificationPreview specifications={specifications} prompts={promptHistory} />
          )}

          {isLoadingUserCommand ? null : isApproveStep ? (
            <div className="flex items-center justify-start gap-4 border-t border-border bg-white px-8 py-6">
              <Button variant="outline" onClick={backToEdit}>
                Request changes
              </Button>
              <Button className="w-full" onClick={handleValidate}>
                <Check />
                Approve
              </Button>
            </div>
          ) : selectedOption ? (
            <PromptEditor
              option={selectedOption}
              isSuperAdmin={isUserSuperAdmin}
              onBack={handleBack}
              onSubmit={handleSubmitPrompt}
            />
          ) : (
            <div className="flex w-full flex-wrap items-center justify-start gap-1 p-2">
              {extensions?.map(extension => (
                <FollopUpOption
                  key={extension.id}
                  Icon={Plus}
                  label={extension.title}
                  onClick={() => handleExtensionOptionClick(extension)}
                />
              ))}
              <FollopUpOption Icon={Plus} label="Custom" onClick={handleCustomClick} />
            </div>
          )}
        </CollapsibleContent>
      </Collapsible>
    </div>
  )
}

function Placeholder({ children }: { children?: React.ReactNode }) {
  return (
    <div className="inline-flex h-16 items-center justify-start gap-6">
      <div className="flex size-16 items-center justify-center rounded-md bg-[#f8f3fe] p-5">
        <div className="relative flex size-6 flex-col items-start justify-start">
          <Logo key="iteration-status-running-icon" color={colors.violet[500]} />
        </div>
      </div>
      <div className="text-base font-medium leading-normal text-stone-900">{children}</div>
    </div>
  )
}

function FollopUpOption({
  label,
  onClick,
  Icon,
}: {
  label?: string
  onClick?: () => void
  Icon: React.FC<{ className?: string }>
}) {
  return (
    <Button variant="secondary" className="bg-stone-200" onClick={onClick}>
      <div className="inline-flex h-10 w-full items-center justify-center gap-2 rounded-md  px-4 py-2">
        {Icon && <Icon className="size-4" />}
        <div className="text-sm font-medium leading-tight text-stone-900">{label}</div>
      </div>
    </Button>
  )
}

const CUSTOM_OPTION_PLACEHOLDER = 'Instructions on what to do next, please be specific'

function isExtensionWithBlueprintId(option: ExtensionOption): option is BaseExtensionOption {
  return option.type === 'extension' && Boolean(option.extension.blueprintId)
}

function isExtensionWithoutBlueprintId(option: ExtensionOption): option is BaseExtensionOption {
  return option.type === 'extension' && !option.extension.blueprintId
}

function isCustomOption(option: ExtensionOption): option is CustomExtensionOption {
  return option.type === 'custom'
}

function getBlueprintIdFromOption(option: ExtensionOption): string | null {
  return isExtensionWithBlueprintId(option) ? option.extension.blueprintId : null
}

function PromptEditor({
  option,
  onBack,
  onSubmit,
  isSuperAdmin = false,
}: {
  option: ExtensionOption
  onBack: () => void
  onSubmit: (props: { prompt: string; blueprintId: string }) => void
  isSuperAdmin: boolean
}) {
  const [prompt, setPrompt] = useState<string>(() => {
    if (isExtensionWithoutBlueprintId(option)) {
      return option.extension.prompt
    } else {
      return ''
    }
  })
  const [blueprintId, setBlueprintId] = useState<string | null>(() => {
    return getBlueprintIdFromOption(option) ?? ''
  })
  const existingPrompt = option.type === 'extension' ? option.extension.prompt : ''
  const isSubmitDisabled = option.type === 'custom' ? prompt === '' : false
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      const length = textareaRef.current.value.length
      textareaRef.current.setSelectionRange(length, length)
    }
  }, [])

  const handleOnSubmit = useCallback(() => {
    if (isExtensionWithBlueprintId(option)) {
      onSubmit({ prompt: `${existingPrompt}\n\n${prompt}`, blueprintId })
    } else if (isExtensionWithoutBlueprintId(option) || isCustomOption(option)) {
      onSubmit({ prompt, blueprintId })
    }
  }, [option, prompt, blueprintId, onSubmit, existingPrompt])

  return (
    <div className="bg-white">
      <div className="flex  flex-col items-start justify-start gap-2 self-stretch border-b border-border bg-stone-100 px-6 py-4">
        <div className="inline-flex items-center justify-start gap-2 py-0.5">
          <div className="flex size-4 items-center justify-center p-0.5">
            <Plus />
          </div>
          <div className="text-sm font-medium leading-tight text-stone-900">
            {option.type === 'extension' ? option.extension.title : 'Custom'}
          </div>
        </div>
        {isExtensionWithBlueprintId(option) && (
          <div className="self-stretch whitespace-pre-wrap text-sm font-normal leading-tight text-stone-500">
            {existingPrompt}
          </div>
        )}
      </div>
      <div className="px-6 py-5">
        <div className="flex w-full flex-col items-stretch gap-4">
          {isSuperAdmin && (
            <Input
              placeholder="Blueprint ID (optional)"
              value={blueprintId}
              onChange={e => setBlueprintId(e.target.value)}
            />
          )}
          <Textarea
            ref={textareaRef}
            variant="ghost"
            resize={false}
            value={prompt}
            rows={3}
            onChange={e => setPrompt(e.target.value)}
            placeholder={
              option.type === 'extension' ? option.extension.placeholder : CUSTOM_OPTION_PLACEHOLDER
            }
          />
        </div>
      </div>
      <div className="flex w-full gap-2 px-6 pb-5">
        <Button size="icon" variant="outline" className="size-10" onClick={onBack}>
          <MoveLeft className="size-4" />
        </Button>
        <Button
          disabled={isSubmitDisabled}
          className="w-full tracking-tight"
          size="lg"
          onClick={handleOnSubmit}
        >
          <span>Request</span>
          <SquareArrowRight className="ml-2 size-4" />
        </Button>
      </div>
    </div>
  )
}
