import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useState, useRef, useEffect } from 'react'
import { FoldVertical, UnfoldVertical, Copy } from 'lucide-react'
import { useClipboardCopy } from '@/lib/hooks.js'
import { useToast } from '@/components/ui/use-toast.js'
import { Button } from '@/components/ui/button'

const COLLAPSED_HEIGHT_REM = 6

export default function AActionContentTerminal({ content, terminalOutputs, errored = false }) {
  const [showTerminalOutputs, setShowTerminalOutputs] = useState(false)
  const [shouldShowExpandButton, setShouldShowExpandButton] = useState(false)
  const terminalContentRef = useRef(null)
  const { copy: copyContent } = useClipboardCopy()
  const { toast } = useToast()

  const className = classNames(
    "p-2 rounded-t-sm mt-1 break-all bg-stone-800 group relative px-4 text-sm font-normal font-['DM Mono'] leading-tight",
    terminalOutputs && 'cursor-pointer ',
    !terminalOutputs && 'rounded-b-sm',
    errored && 'bg-red-700 text-zinc-200',
    !errored && 'bg-black text-green-400'
  )

  const handleCopyToClipboard = content => {
    copyContent(
      content,
      () =>
        toast({
          title: 'Copied to clipboard! 🎉',
        }),
      () =>
        toast({
          variant: 'destructive',
          title: 'Failed to copy to clipboard 😔',
        })
    )
  }

  let terminalOutputContent = ''
  if (typeof terminalOutputs === 'string') {
    terminalOutputContent = terminalOutputs
  } else {
    terminalOutputs?.forEach(content => {
      terminalOutputContent += `${content.replace('- stdout:', '').replace('- stderr:', '').replace('- exit_code: 0', '').trimLeft()}`
    })
  }

  useEffect(() => {
    if (terminalContentRef.current) {
      const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize)
      const maxCollapsedHeight = COLLAPSED_HEIGHT_REM * rootFontSize
      const contentHeight = terminalContentRef.current.scrollHeight
      setShouldShowExpandButton(contentHeight > maxCollapsedHeight)
    }
  }, [terminalOutputContent])

  return (
    <>
      <div className={className}>
        <span>{content}</span>
        <div
          className="absolute right-3 top-3 z-10 cursor-pointer opacity-0 group-hover:opacity-100"
          onClick={() => handleCopyToClipboard(content)}
        >
          <Copy className="size-4 text-gray-500 hover:text-black" />
        </div>
      </div>
      {!!terminalOutputContent && (
        <div className="group relative flex w-full flex-col gap-2">
          <div
            className="absolute right-3 top-3 z-10 cursor-pointer  opacity-0 group-hover:opacity-100"
            onClick={() => handleCopyToClipboard(terminalOutputContent)}
          >
            <Copy className="size-4 text-gray-500 hover:text-black" />
          </div>
          <div
            ref={terminalContentRef}
            className={`font-['DM Mono'] flex w-full max-w-full flex-col whitespace-pre-wrap break-all rounded-b-sm bg-black p-4 text-sm font-normal leading-tight text-neutral-300 ${
              !showTerminalOutputs && `max-h-[${COLLAPSED_HEIGHT_REM}rem] overflow-hidden`
            }`}
          >
            {terminalOutputContent}
          </div>
          {shouldShowExpandButton && (
            <Button
              className="inline-flex h-8 cursor-pointer items-center justify-center gap-2 rounded-md bg-stone-100 px-3 py-1.5 text-stone-900"
              variant="secondary"
              onClick={() => {
                setShowTerminalOutputs(prev => !prev)
              }}
            >
              {showTerminalOutputs ? (
                <FoldVertical className="relative size-4" />
              ) : (
                <UnfoldVertical className="relative size-4" />
              )}

              <div className="font-['Inter'] text-sm font-medium leading-tight ">
                {showTerminalOutputs ? 'Collapse Output' : 'Expand Output'}
              </div>
            </Button>
          )}
        </div>
      )}
    </>
  )
}

AActionContentTerminal.propTypes = {
  content: PropTypes.string,
  terminalOutputs: PropTypes.arrayOf(PropTypes.string),
}
