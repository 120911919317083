import { useCallback, useState } from 'react'
import { GUNSLINGER_JOB_STATUSES } from '@/const/const'
import { Button } from '@/components/ui/button'
import { StopCircle } from 'lucide-react'
import { stopVMJobForIterationFirebaseFunction } from '@/services/Firebase'
import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'

interface VMStatusButtonProps {
  iterationId: string
  vmStatus: string | null
  updatedAt?: Date
  onVMStopped?: (iterationId: string) => void
}

function allowToKillVMForStatus(status: string | null) {
  return status === GUNSLINGER_JOB_STATUSES.RUNNING
}

export function VMStopButton({
  iterationId,
  vmStatus,
  onVMStopped = () => {},
}: VMStatusButtonProps) {
  const [isWorking, setIsWorking] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const handleStopVM = useCallback(async () => {
    setIsWorking(true)
    try {
      await stopVMJobForIterationFirebaseFunction({ iterationId })
      onVMStopped(iterationId)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_STOP_VM, { iterationId })
    } catch (error) {
      console.error('Error stopping VM', error)
    } finally {
      setIsWorking(false)
      setIsDone(true)
      setShowConfirmDialog(false)
    }
  }, [iterationId, onVMStopped])

  if (!allowToKillVMForStatus(vmStatus)) {
    return null
  }

  if (isDone) {
    return null
  }

  return (
    <>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => setShowConfirmDialog(true)}
        disabled={isWorking}
        className="text-red-500 hover:bg-transparent hover:text-red-600"
      >
        <StopCircle className="size-4" />
        <span className="font-medium">{isWorking ? 'Working...' : 'Stop'}</span>
      </Button>

      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cancel this iteration?</DialogTitle>
            <DialogDescription>
              Are you sure you want to cancel this iteration? You&#39;ll loose everything Proofs
              already built. This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex gap-2">
            <Button variant="outline" onClick={() => setShowConfirmDialog(false)}>
              No, keep working
            </Button>
            <Button variant="destructive" onClick={handleStopVM} disabled={isWorking}>
              Yes, Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
