import { Card, CardContent } from '@/components/ui/card'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import * as Sentry from '@sentry/react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics'
import { LOCAL_STORAGE_EMAIL_LOGIN_KEY } from '@/const/const'
import { sendMagicLink } from '@/services/Firebase'
import { useMutation } from '@tanstack/react-query'
import { Loader } from 'lucide-react'
import { useToast } from '@/components/ui/use-toast.js'

const formSchema = z.object({
  email: z.string().email(),
})

type LoginSchema = z.infer<typeof formSchema>

const domainOverrides = /localhost|\.vercel\.app/
const devDomain =
  globalThis.window && domainOverrides.test(window.location.host) ? window.location.host : undefined

export function EnterEmailStep({
  setEmail,
  onNext,
}: {
  email: string | null
  setEmail: (email: string) => void
  onNext: () => void
}) {
  const form = useForm<LoginSchema>({
    resolver: zodResolver(formSchema),
  })
  const { toast } = useToast()

  const loginMutation = useMutation({
    mutationKey: ['login'],
    mutationFn: async (email: string) => {
      const payload = devDomain ? { email, devDomain } : { email }
      const res = await sendMagicLink(payload)
      return res.data
    },
    onSuccess(_data, email) {
      window.localStorage.setItem(LOCAL_STORAGE_EMAIL_LOGIN_KEY, email)
      analyticsTrackEvent(ANALYTIC_EVENTS.AUTH_LOGIN_INIT, { email })
      setEmail(email)
      onNext()
    },
    onError(error) {
      analyticsTrackEvent(ANALYTIC_EVENTS.AUTH_SIGN_IN_ERROR, { error: error.message })
      toast({
        title: 'Something went wrong.',
        description: 'We were informed of this issue. Please try again later.',
        variant: 'destructive',
      })
      Sentry.captureException(error)
    },
  })

  const sendSignInLinkViaEmail = async (data: LoginSchema) => {
    loginMutation.mutate(data.email)
  }

  return (
    <Card className="rounded-lg border-base-border bg-base-card p-8 shadow-none">
      <CardContent>
        <Form {...form}>
          <form
            className="space-y-8"
            onSubmit={form.handleSubmit(sendSignInLinkViaEmail)}
            method="POST"
          >
            <div className="space-y-4">
              <div className="font-pp-supply-sans text-3xl font-normal">Sign in</div>
              <div className="text-sm text-base-muted-foreground">
                Get a magic link sent to your email. <br />
                Make sure you have access to your inbox.
              </div>
            </div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full" type="submit" disabled={loginMutation.isPending}>
              {!loginMutation.isPending ? (
                'Send Magic Link'
              ) : (
                <>
                  <Loader className="animate-spin" /> Working...
                </>
              )}
            </Button>
            <div className="text-sm text-base-muted-foreground">
              Interested in Proofs?{' '}
              <a
                className="underline"
                target="_blank"
                rel="noreferrer"
                href="https://proofsio.notion.site/46977e3eda9f464695549b9c4a81c329"
              >
                Early access?
              </a>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}
