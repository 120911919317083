import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { Search } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import { MarkdownContent } from '@/components/actions/components/MarkdownContent.tsx'

export default function ResearchAction({ action, isLast = false }) {
  let detailsContent = ''
  let content = ''

  const question = action?.args?.question
  if (question) {
    detailsContent += `## QUESTION:\n\n${question}`
  }

  action?.outputs?.forEach(output => {
    content += `${output.result.content} \n\n`
  })

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={Search}
      isLast={isLast}
      title={action.args?.comment || 'Research'}
    >
      <AActionContentGenericLayout>
        <MarkdownContent detailsContent={content} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ResearchAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
