import { CheckCheck, Pause, Play, OctagonX } from 'lucide-react'
import Logo from '@/assets/svg-components/Logo'
import colors from 'tailwindcss/colors'
import { Iteration } from '@/services/firebase_shared_types'
import {
  ITERATION_STATUSES,
  ITERATION_STATUS_TO_COMMAND,
  GUNSLINGER_JOB_STATUSES,
  ITERATION_COMMANDS,
} from '@/const/const'
import { calculateDuration, formatIterationDate } from '@/lib/date-utils'
import { Button } from '@/components/ui/button'
import { sendUserCommand } from '@/lib/sendUserCommand'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { VMStopButton } from '@/components/molecules/iteration-details/VMStopButton'

interface IterationInfoProps {
  iteration: Iteration
  iterationMeta: any
}

export function IterationInfo({ iteration, iterationMeta }: IterationInfoProps) {
  const [isChangingStatus, setIsChangingStatus] = useState(false)
  const [optimisticVMStatus, setOptimisticVMStatus] = useState(null)
  const vmStatusOnStopped = useRef(null)
  const isAwaiting = iterationMeta?.awaitingCommand
  const iterationId = iterationMeta?.id
  const iterationStatus = iterationMeta?.status

  const [transitionInProgress, setTransitionInProgress] =
    useState<null | ITERATION_STATUSES.PAUSING>(null)
  const prevIterationStatus = useRef<null | ITERATION_STATUSES>(null)
  const handleStatusChange = async () => {
    setIsChangingStatus(true)
    const command = ITERATION_STATUS_TO_COMMAND[iterationStatus]
    setTransitionInProgress(command)
    prevIterationStatus.current = iterationStatus
    await sendUserCommand(iterationId, { command })
    setIsChangingStatus(false)
  }

  useEffect(() => {
    if (prevIterationStatus.current !== iterationStatus) {
      setTransitionInProgress(null)
      prevIterationStatus.current = null
    }
  }, [iterationStatus])

  useEffect(() => {
    if (vmStatusOnStopped.current !== iterationMeta?.vmStatus) {
      setOptimisticVMStatus(null)
      vmStatusOnStopped.current = null
    }
  }, [iterationMeta?.vmStatus])

  const handleVMStopped = useCallback(() => {
    setOptimisticVMStatus(GUNSLINGER_JOB_STATUSES.STOPPING)
    vmStatusOnStopped.current = iterationMeta?.vmStatus
  }, [iterationMeta?.vmStatus])

  const vmStatus = useMemo(() => {
    if (optimisticVMStatus) {
      return optimisticVMStatus
    } else {
      return iterationMeta?.vmStatus
    }
  }, [optimisticVMStatus, iterationMeta])

  function getTransitionLabel() {
    if (optimisticVMStatus) {
      return optimisticVMStatus
    } else if (
      [GUNSLINGER_JOB_STATUSES.STOPPED, GUNSLINGER_JOB_STATUSES.STOPPING].includes(vmStatus)
    ) {
      return vmStatus
    } else if (transitionInProgress) {
      return (
        {
          [ITERATION_COMMANDS.PAUSE]: 'pausing',
          [ITERATION_COMMANDS.RESUME]: 'resuming',
        }[transitionInProgress] ?? transitionInProgress
      )
    } else {
      return iterationStatus
    }
  }

  const isInTransition =
    optimisticVMStatus ||
    transitionInProgress ||
    [GUNSLINGER_JOB_STATUSES.STOPPED, GUNSLINGER_JOB_STATUSES.STOPPING].includes(vmStatus) ||
    [ITERATION_STATUSES.PAUSING, ITERATION_STATUSES.RESTARTING].includes(iterationStatus)

  if (isInTransition) {
    const transitionLabel = getTransitionLabel()
    return (
      <div className="border-t-none flex h-20 items-center justify-start rounded-b-lg border border-stone-200 p-6 pr-8 pt-6">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex size-6 flex-col items-center justify-center p-0.5">
            <Logo key="element-running-logo" color={colors.stone[500]} />
          </div>
          <div className="flex items-center justify-start gap-0.5">
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              {`Iteration is ${transitionLabel.toLowerCase()}...`}
            </div>
          </div>
        </div>
      </div>
    )
  } else if (
    iterationStatus === ITERATION_STATUSES.ERRORED ||
    iterationStatus === ITERATION_STATUSES.FAILED ||
    vmStatus === GUNSLINGER_JOB_STATUSES.ERROR
  ) {
    return (
      <div className="inline-flex h-20 items-center justify-start rounded-bl-lg rounded-br-lg border-b border-l border-r border-stone-200 pb-6 pl-6 pr-8 pt-6">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex size-6 flex-col items-center justify-center p-0.5">
            <OctagonX className="text-red-500" />
          </div>
          <div className="flex items-center justify-start gap-0.5">
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Iteration failed
            </div>
          </div>
        </div>
      </div>
    )
  } else if (iterationStatus === ITERATION_STATUSES.DONE) {
    return (
      <div className="inline-flex h-20 items-center justify-between rounded-bl-lg rounded-br-lg border-b border-l border-r border-stone-200 pb-6 pl-6 pr-8 pt-6">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex size-6 flex-col items-center justify-center p-0.5">
            <div className="relative flex size-5 flex-col items-start justify-start">
              <CheckCheck className="text-green-500" />
            </div>
          </div>
          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
            {`Iteration done Today at ${formatIterationDate(iteration?.updatedAt, 'unknown')} without any issues`}
          </div>
        </div>
        <div className="text-right font-['Inter'] text-xs font-normal leading-none text-stone-500">
          {calculateDuration(iteration?.createdAt, iteration?.updatedAt)}
        </div>
      </div>
    )
  } else if (iterationStatus === ITERATION_STATUSES.RUNNING) {
    const { element, step } = findElementAndStepByStatus(iteration, ITERATION_STATUSES.RUNNING)
    return (
      <div className="inline-flex h-20 items-center justify-between rounded-bl-lg rounded-br-lg border-b border-l border-r border-stone-200 pb-6 pl-6 pr-8 pt-6">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex size-6 shrink grow basis-0 flex-col items-center justify-center self-stretch p-0.5">
            <Logo key="element-running-logo" color={colors.violet[500]} />
          </div>
          <div className="flex items-center justify-start gap-0.5">
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              {step?.name ?? element?.name ?? 'Iteration is running...'}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-start gap-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={handleStatusChange}
            disabled={isChangingStatus}
            className="gap-2 hover:bg-transparent"
          >
            <Pause className="size-4" />
            {isChangingStatus ? 'Pausing...' : 'Pause'}
          </Button>
          {Boolean(vmStatus) && (
            <VMStopButton
              iterationId={iterationId}
              vmStatus={iteration.vmStatus}
              updatedAt={iteration.updatedAt}
              onVMStopped={handleVMStopped}
            />
          )}
        </div>
      </div>
    )
  } else if (iterationStatus === ITERATION_STATUSES.RUN_REQUESTED) {
    return (
      <div className="inline-flex h-20 items-center justify-start rounded-bl-lg rounded-br-lg border-b border-l border-r border-stone-200 pb-6 pl-6 pr-8 pt-6">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex size-6 flex-col items-center justify-center p-0.5">
            <Logo key="element-running-logo" color={colors.stone[500]} />
          </div>
          <div className="flex items-center justify-start gap-0.5">
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Starting virtual machine to work on iteration
            </div>
          </div>
        </div>
      </div>
    )
  } else if (iterationStatus === ITERATION_STATUSES.PAUSED) {
    return (
      <div className="inline-flex h-20 items-center justify-between rounded-bl-lg rounded-br-lg border-b border-l border-r border-stone-200 pb-6 pl-6 pr-8 pt-6">
        <div className="flex items-center justify-start gap-4">
          <div className="inline-flex size-6 shrink grow basis-0 flex-col items-center justify-center self-stretch p-0.5">
            <Logo key="element-running-logo" color={colors.stone[500]} />
          </div>
          <div className="flex items-center justify-start gap-0.5">
            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-500">
              Iteration paused
            </div>
          </div>
        </div>
        <div className="flex items-center justify-start gap-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={handleStatusChange}
            disabled={isChangingStatus}
            className="gap-2 hover:bg-transparent"
          >
            <Play className="size-4" />
            {isChangingStatus ? 'Working...' : 'Resume'}
          </Button>
          {Boolean(vmStatus) && (
            <VMStopButton
              iterationId={iterationId}
              vmStatus={iteration.vmStatus}
              updatedAt={iteration.updatedAt}
              handleVMStopped={handleVMStopped}
            />
          )}
        </div>
      </div>
    )
  }
}

function findElementAndStepByStatus(iteration: Iteration, status: IterationStatus) {
  // Find the first element with the specified status
  const matchingElement = Object.values(iteration.elements).find(
    element => element.status === status
  )

  if (!matchingElement) {
    return { element: null, step: null }
  }

  // Find the first step in the matching element with the specified status
  const matchingStep = Object.values(matchingElement.steps).find(step => step.status === status)

  return { element: matchingElement, step: matchingStep }
}
