import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { Wand } from 'lucide-react'
import { actionShape } from '@/components/propTypes.js'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal'
import { prettyPrintField } from '@/lib/iteration-utils.ts'

export default function DefaultAction({ action, isLast = false }) {
  let detailsContent = ''
  detailsContent += `## Inputs\n`
  const args = action?.args
  detailsContent += prettyPrintField(args)

  const outputs = action?.outputs

  if (outputs) {
    detailsContent += `## Outputs:\n`
    outputs.forEach(output => {
      detailsContent += prettyPrintField(output)
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      actionIcon={Wand}
      isLast={isLast}
      title={action.args?.comment || convertString(action.name)}
    >
      <AActionContentGenericLayout>
        <AActionContentTerminal
          content={'Inputs'}
          terminalOutputs={prettyPrintField(args)}
          collapsedHeightRem={19}
        />
        <AActionContentTerminal
          content="Outputs"
          terminalOutputs={prettyPrintField(outputs)}
          collapsedHeightRem={19}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

DefaultAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}

function convertString(input) {
  // Replace underscores with spaces
  let converted = input.replace(/_/g, ' ')

  // Capitalize the first letter
  converted = converted.charAt(0).toUpperCase() + converted.slice(1)

  return converted
}
