export const LOCAL_STORAGE_EMAIL_LOGIN_KEY = 'emailLogin'

export const PROJECT_STATUSES = {
  IN_PROGRESS: 'INPROGRESS',
  READY: 'READY',
  QUEUED: 'QUEUED',
  NOT_STARTED: 'NOTSTARTED',
}

export const ELEMENT_STATUSES = {
  RUNNING: 'RUNNING',
  AWAITING: 'NEW',
  DONE: 'DONE',
  FAILED: 'FAILED',
}

export const ITERATION_STATUS_VALUES = [
  'NEW',
  'RUN_REQUESTED',
  'STARTING_UP',
  'BUILDING_DOCKER_CONTAINER',
  'SYNCING_SOURCE_CODE',
  'RUNNING',
  'PAUSED',
  'PAUSING',
  'RESTARTING',
  'DONE',
  'FAILED',
  'ROLLBACK_REQUESTED',
  'ROLLBACK',
  'ERRORED',
  'AWAITING',
  'QUEUED',
] as const
export type IterationStatus = (typeof ITERATION_STATUS_VALUES)[number]

export const ITERATION_STATUSES = Object.fromEntries(
  ITERATION_STATUS_VALUES.map(status => [status, status])
) as { [k in IterationStatus]: k }

export const GUNSLINGER_JOB_STATUSES = {
  QUEUED: 'QUEUED',
  STARTING: 'STARTING',
  STARTED: 'STARTED',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  STOPPING: 'STOPPING',
  ERROR: 'ERROR',
}

export const ITERATION_COMPUTED_STATUS_VALUES = [
  ...ITERATION_STATUS_VALUES,
  GUNSLINGER_JOB_STATUSES.STOPPED,
  GUNSLINGER_JOB_STATUSES.STOPPING,
  GUNSLINGER_JOB_STATUSES.ERROR,
] as const
export type IterationComputedStatus = (typeof ITERATION_COMPUTED_STATUS_VALUES)[number]

export const ITERATION_COMPUTED_STATUSES = Object.fromEntries(
  ITERATION_COMPUTED_STATUS_VALUES.map(status => [status, status])
) as { [k in IterationComputedStatus]: k }

export const ITERATION_COMMANDS = {
  PAUSE: 'PAUSE',
  RESUME: 'RESUME',
  EXTEND: 'EXTEND',
  RESTART: 'RESTART',
  SUGGESTION: 'SUGGESION',
  ROLLBACK: 'ROLLBACK',
  INPUT_REQUEST: 'INPUT_REQUEST',
  INPUT_ANSWER: 'INPUT_ANSWER',
  ROLE_MESSAGE: 'ROLE_MESSAGE',
} as const

export const STEP_STATUSES = ELEMENT_STATUSES
export const ACTION_STATUSES = ELEMENT_STATUSES

export const PROJECT_STATUSES_LABELS = {
  [PROJECT_STATUSES.IN_PROGRESS]: 'AI is working',
  [PROJECT_STATUSES.READY]: 'Ready for review',
  [PROJECT_STATUSES.QUEUED]: 'Queued',
  [PROJECT_STATUSES.NOT_STARTED]: 'Awaiting prompt',
}

export const PROJECT_STATUSES_COLORS = {
  [PROJECT_STATUSES.IN_PROGRESS]: 'sky',
  [PROJECT_STATUSES.READY]: 'lime',
  [PROJECT_STATUSES.QUEUED]: 'amber',
  [PROJECT_STATUSES.NOT_STARTED]: 'zinc',
}
export const ACL_ROLES = {
  // superadmin, admin, manager, creator, viewer
  SUPERADMIN: 'superadmin',
  ADMIN: 'admin',
  MANAGER: 'manager',
  CREATOR: 'creator',
  VIEWER: 'viewer',
}

export function getStatusColor(status: string) {
  return PROJECT_STATUSES_COLORS[status] || 'zinc'
}

export const ITERATION_STATUS_TO_COMMAND = {
  [ITERATION_STATUSES.RUNNING]: ITERATION_COMMANDS.PAUSE,
  [ITERATION_STATUSES.PAUSED]: ITERATION_COMMANDS.RESUME,
  [ITERATION_STATUSES.AWAITING]: ITERATION_COMMANDS.PAUSE,
  [ITERATION_STATUSES.ERRORED]: ITERATION_COMMANDS.RESUME,
  [ITERATION_STATUSES.RUN_REQUESTED]: ITERATION_COMMANDS.PAUSE,
  [ITERATION_STATUSES.QUEUED]: ITERATION_COMMANDS.PAUSE,
}

export const ITERATION_COMMAND_TO_STATUS = {
  [ITERATION_COMMANDS.PAUSE]: ITERATION_STATUSES.PAUSING,
  [ITERATION_COMMANDS.RESUME]: ITERATION_STATUSES.RUN_REQUESTED,
}

export const TEMPLATE_WIDGET_TYPE = {
  TEXT_SHORT: 'text-short',
  TEXT_LONG: 'text-long',
}

// First, let's create a type for the GUNSLINGER_JOB_STATUSES values
export type GunslingerJobStatus =
  (typeof GUNSLINGER_JOB_STATUSES)[keyof typeof GUNSLINGER_JOB_STATUSES]
