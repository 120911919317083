import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/16/solid'

import { Button } from '@/components/catalyst/button'

import { Badge } from '@/components/catalyst/badge'

import MStepsList2 from '@/components/molecules/iteration-details/MStepsList2.jsx'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'
import { useSearchParams } from 'react-router-dom'
import { formatIndex, safeParseInt } from '@/lib/params-utils.js'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import MStepsList from '@/components/molecules/iteration-details/MStepList.jsx'
import { cn } from '@/lib/utils.js'
import { ELEMENT_STATUSES } from '@/const/const'
import Logo from '@/assets/svg-components/Logo.jsx'
import { XOctagon } from 'lucide-react'
import colors from 'tailwindcss/colors.js'

export function MElementListItem({
  element,
  lastTick = Date.now(),
  isLast = false,
  expandAllDetails = false,
  filterOutLLMActions = true,
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedElementIndex = safeParseInt(searchParams.get('element'))
  const steps = useMemo(() => {
    if (!element?.steps) {
      return null
    }

    const steps = Object.entries(element?.steps).map(([key, value]) => {
      return { stepId: key, ...value, repoURI: element.repoURI }
    })
    return steps.sort((a, b) => a.index - b.index)
  }, [element])

  const isDone = useMemo(() => {
    return element.status === 'DONE'
  }, [element])

  const isRunning = useMemo(() => {
    return element.status === 'RUNNING'
  }, [element])

  const isAwaiting = useMemo(() => {
    return element.status === 'NEW'
  }, [element])

  function ElementStatusAwaiting({ index }) {
    return (
      <div className="flex place-self-stretch items-center justify-center rounded-md bg-stone-200 min-h-16">
        <div className="font-pp-supply-sans text-center text-2xl font-normal leading-9 text-stone-400">
          {formatIndex(index)}
        </div>
      </div>
    )
  }

  function ElementStatusRunning() {
    return (
      <div className="flex place-self-stretch items-center justify-center rounded-md bg-[#f8f3fe] min-h-16">
        <div className="inline-flex shrink grow basis-0 flex-col items-center justify-center self-stretch">
          <Logo key="element-running-logo" color={colors.violet[500]} className='size-5' />
        </div>
      </div>
    )
  }

  function ElementStatusDone({ index }) {
    return (
      <div className="flex place-self-stretch items-center justify-center rounded-md bg-green-500/10 min-h-16">
        <span className="font-pp-supply-sans text-2xl leading-9 text-green-500">
          {formatIndex(index)}
        </span>
      </div>
    )
  }

  function ElementStatusFailed() {
    return (
      <div className="flex place-self-stretch items-center justify-center rounded-md bg-red-50 min-h-16">
          <XOctagon className="size-5 text-red-500" />
      </div>
    )
  }

  const [elementStatusComponent, textColor, backgroundColor] = useMemo(() => {
    switch (element.status) {
      case ELEMENT_STATUSES.AWAITING:
        return [
          <ElementStatusAwaiting key="element-status-awaiting" index={element.index} />,
          'zinc-500',
          'transparent',
        ]
      case ELEMENT_STATUSES.RUNNING:
        return [<ElementStatusRunning key="element-status-running" />, 'violet-500', 'white']
      case ELEMENT_STATUSES.DONE:
        return [
          <ElementStatusDone key="element-status-done" index={element.index} />,
          'green-500',
          'transparent',
        ]
      case ELEMENT_STATUSES.FAILED:
        return [<ElementStatusFailed key="element-status-failed" />, 'red-500', 'transparent']
      default:
        return [
          <ElementStatusAwaiting key="element-status-default" index={element.index} />,
          'zinc-500',
          'transparent',
        ]
    }
  }, [element.status, element.index])

  const [showSteps, setShowSteps] = useState(
    expandAllDetails || !isDone || selectedElementIndex === element?.index
  )
  const handleShowAllSteps = useCallback(() => {
    if (!showSteps) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXPAND_STAGE, {
        iterationId: element?.iterationId,
        elementIndex: element?.index,
        elementStatus: element?.status || 'N/A',
      })
    }

    setShowSteps(!showSteps)

    setSearchParams(
      prevParams => {
        const newParams = new URLSearchParams(prevParams)
        if (element?.index) {
          newParams.set('element', element.index.toString())
        } else {
          newParams.delete('element')
        }
        newParams.delete('step')
        newParams.delete('action')
        return newParams
      },
      { replace: false }
    )
  }, [element, showSteps])

  return (
    <Collapsible
      open={showSteps}
      onOpenChange={handleShowAllSteps}
      data-index={element.index}
      className=""
    >
      <CollapsibleTrigger asChild>
      <div
        className={cn(
          'grid grid-cols-[64px,1fr,max-content] w-[800px] items-center justify-start gap-4 rounded-lg border border-stone-200 p-1 pr-4',
          {
            'mb-2 bg-white': showSteps,
          }
        )}
      >
        {/* <div className="flex h-full w-full items-center justify-between space-x-4 py-1"> */}
          {elementStatusComponent}
            <div
              className={cn(
                'inline-flex  py-4 shrink grow basis-0 cursor-pointer flex-col items-start justify-center gap-1 overflow-hidden'
              )}
            >
              <div
                className="w-full truncate font-['Inter'] text-base  font-medium leading-normal text-stone-900"
                title={element.name}
              >
                {element.name}
              </div>
              {showSteps && (
                <div className="line-clamp-2 w-full overflow-hidden text-ellipsis font-['Inter'] text-sm font-normal leading-tight text-stone-500">
                  {element.description}
                </div>
              )}
            </div>

          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center justify-center gap-2.5 rounded-md border border-stone-200 px-2 py-1">
              <div className="font-['Inter'] text-xs font-medium leading-none text-stone-500">
                {steps.length === 1 ? '1 Step' : `${steps.length} Steps`}
              </div>
            </div>

            <Button plain>{showSteps ? <ChevronUpIcon /> : <ChevronDownIcon />}</Button>
          </div>
        {/* </div> */}
      </div>
          </CollapsibleTrigger>

      <CollapsibleContent className="">
        <div className="w-full flex-grow rounded-lg bg-white">
          <MStepsList
            steps={steps}
            lastTick={lastTick}
            expandAllDetails={expandAllDetails}
            filterOutLLMActions={filterOutLLMActions}
          />
        </div>
      </CollapsibleContent>
    </Collapsible>
  )
}

export default function MElementsList({
  elements,
  lastTick = Date.now(),
  expandAllDetails = false,
  filterOutLLMActions = true,
}) {
  const [searchParams] = useSearchParams()
  const selectedElementIndex = safeParseInt(searchParams.get('element'))
  const listRef = useRef(null)

  useEffect(() => {
    if (searchParams.get('step') != null) {
      return
    }
    if (selectedElementIndex !== null && listRef.current) {
      const selectedElement = listRef.current.querySelector(
        `[data-index="${selectedElementIndex}"]`
      )
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [])

  return (
    <div ref={listRef} className="inline-flex w-[800px] flex-col items-start justify-start gap-4">
      {elements?.map((element, index) => (
        <MElementListItem
          key={element.id}
          element={element}
          lastTick={lastTick}
          isLast={index === elements.length - 1}
          expandAllDetails={expandAllDetails}
          filterOutLLMActions={filterOutLLMActions}
        />
      ))}
    </div>
  )
}

MElementsList.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      updatedAtRelative: PropTypes.string,
      stack: PropTypes.arrayOf(PropTypes.string),
      steps: PropTypes.object,
      elementId: PropTypes.string,
      index: PropTypes.number,
      repoURI: PropTypes.string,
      iterationId: PropTypes.string,
    })
  ),
  lastTick: PropTypes.number,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}

MElementListItem.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedAtRelative: PropTypes.string,
    stack: PropTypes.arrayOf(PropTypes.string),
    steps: PropTypes.object,
    elementId: PropTypes.string,
    index: PropTypes.number,
    repoURI: PropTypes.string,
    iterationId: PropTypes.string,
  }),
  lastTick: PropTypes.number,
  isLast: PropTypes.bool,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}
