import classNames from 'classnames'

export default function AActionContentFiles({
  content,
  terminalOutputs,
}: {
  content: string
  terminalOutputs?: string[]
}) {
  const className = classNames(
    'bg-base-background min-h-10 content-center text-stone-50 p-1 pl-4 rounded-t-md break-all',
    'text-sm',
    terminalOutputs && 'cursor-pointer border-b border-dashed border-zinc-200 ',
    !terminalOutputs && 'rounded-b-md'
  )
  return <div className={className}>{content}</div>
}
