import { Card, CardContent } from '@/components/ui/card'
import { Check } from 'lucide-react'

export function EmailLinkSentStep({ email }: { email: string | null; onNext: () => void }) {
  return (
    <Card className="rounded-lg border-base-border bg-base-card p-8 shadow-none">
      <CardContent>
        <div className="space-y-8">
          <div className="space-y-4">
            <div className="font-pp-supply-sans text-3xl font-normal">Sign in</div>
            <div>
              <Check className="text-2xl text-green-500" />
            </div>
            <div className="space-y-1">
              <div className="font-medium text-base-foreground">
                If an account exists, a magic link was sent to {email}
              </div>
              <div className="text-sm text-base-muted-foreground">
                Please check your inbox and spam folder and click on the link if you received it.
              </div>
            </div>
          </div>
          <div className="text-sm text-base-muted-foreground">
            Interested in Proofs?{' '}
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href="https://proofsio.notion.site/46977e3eda9f464695549b9c4a81c329"
            >
              Early access?
            </a>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
