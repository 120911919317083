import { Dialog } from '@/components/catalyst/dialog.jsx'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  authorizeGithub,
  createIterationForProject,
  createProjectFirebaseFunction,
  fetchGithubRepositories,
  installGithub,
} from '@/services/Firebase.ts'
import { ANALYTIC_EVENTS, analyticsTrackEvent } from '@/services/Analytics.js'
import { useToast } from '@/components/ui/use-toast.js'
import PropTypes from 'prop-types'
import { stackTemplate, technologiesOptions } from '@/const/stackTemplates.ts'
import { Check, Container, FileText, FolderOpen, MoveLeft, Rocket } from 'lucide-react'
import { Label } from '@/components/ui/label'
import { Controller, useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { useHelpContent } from '@/components/molecules/project-details/CreateProject/useHelpContent.js'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { cx } from 'class-variance-authority'
import { zodResolver } from '@hookform/resolvers/zod'
import useStore, { useIsUserSuperAdmin } from '@/stores/useStore.js'
import { Textarea } from '@/components/ui/textarea'
import { useNavigate } from 'react-router-dom'
import { SiGithub } from '@icons-pack/react-simple-icons'
import {
  ProjectData,
  projectSchema,
  RepositoryData,
  repositorySchema,
} from '@/components/molecules/project-details/CreateProject/schemas'
import { ConfigurationTemplate, IterationDefaults, WizardStep } from './types'
import { useQuery } from '@tanstack/react-query'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { constructPrompt } from '@/components/molecules/project-details/CreateProject/constructPrompt'
import { useAnalystEnvFields } from './useAnalystEnvFields.ts'
import { Checkbox } from '@/components/catalyst/checkbox.jsx'
import Logo from '@/assets/svg-components/Logo.jsx'
import { FormErrorField } from '@/components/ui/form-error-field.tsx'

const START_GUNSLINGER_FOR_NEW_ITERATION = window?.location?.host?.startsWith('old.') ? false : true

export default function MCreateProjectDialog({
  organizationId,
  teamId,
  isOpen,
  onClose,
  onProjectCreated = () => {},
}: {
  organizationId?: string
  teamId?: string
  isOpen: boolean
  onClose: () => void
  onProjectCreated: () => void
}) {
  const configurationTemplates = useStore(state => state.configurationTemplates)
  const adminFields = useAnalystEnvFields()

  const { toast } = useToast()

  const { response, createFn, error: creationError, isCreating, resetCreateFn } = useCreateProject()

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    if (response) {
      toast({
        title: 'Project created successfully! 🎉',
      })
      onProjectCreated(response?.data || {})

      resetCreateFn()
      handleClose()
    }
    if (creationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating project 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating project:', creationError)
      resetCreateFn()
    }
  }, [response, creationError, toast, resetCreateFn, handleClose, onProjectCreated])

  const onSubmit = (
    projectData: WizardData,
    configurationTemplate: ConfigurationTemplate,
    newPrompt: string
  ) => {
    const configurationTemplateFields = [
      ...adminFields,
      ...(configurationTemplate?.iterationDefaultsTemplate?.environment?.fields ?? []),
    ]
    const payload: CreateProjectPayload = {
      prompt: newPrompt,
      organizationId: projectData.project.organizationId,
      teamId: projectData.project.teamId,
      usecaseFields: configurationTemplate?.iterationDefaultsTemplate?.usecase?.fields,
      formData: {
        name: projectData.project.name,
        projectConfiguration: projectData.project,
        repoository: projectData.repository,
        environment: Object.entries(projectData.environment)
          .filter(([key, value]) => value != null && value !== '')
          .map(([key, value]) => {
            const field = configurationTemplateFields.find(f => f.key === key)
            const dontEncrypt = field?.dontEncrypt
            return { key, value, dontEncrypt }
          })
          .reduce((acc, { key, ...field }) => ({ ...acc, [key]: field }), {}),
      },
    }
    if (!isCreating) {
      createFn(payload)
    }
  }

  if (configurationTemplates == null) {
    return (
      <Dialog
        className="max-h-[90vh] overflow-hidden p-[0] "
        size="3xl"
        open={isOpen}
        onClose={onClose}
      >
        <div className="inline-flex h-[412px] w-full flex-col items-end justify-center gap-4 rounded-lg bg-stone-100 p-4 shadow">
          <div className="inline-flex items-center justify-start gap-4 self-stretch">
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-4">
              <div className="flex h-[380px] flex-col items-center justify-center gap-8 self-stretch rounded-lg border border-stone-200 bg-white px-8 py-20 shadow">
                <div className="inline-flex h-[120px] w-[120px] items-center justify-center rounded-full border border-[#954ee2] bg-gradient-to-b from-[#954ee2] to-[#954ee2]" />
                <div className="flex h-[68px] flex-col items-center justify-start gap-2 self-stretch">
                  <div className="text-center font-pp-supply-sans text-3xl font-normal leading-10 text-stone-900">
                    Loading initial data
                  </div>
                  <div className="self-stretch text-center font-['Inter'] text-sm font-normal leading-tight text-stone-500">
                    It shouldn’t take more than a couple of minutes...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  return (
    <>
      <Dialog
        className="max-h-[97vh] overflow-hidden p-[0] "
        size="4xl"
        open={isOpen}
        onClose={onClose}
        static={true}
        preventOutsideClick={true}
      >
        <MCreateProjectWizard
          onCreateProject={onSubmit}
          submittingInProgress={isCreating}
          configurationTemplates={configurationTemplates}
          organizationId={organizationId}
          teamId={teamId}
          handleCancel={handleClose}
        />
      </Dialog>
    </>
  )
}

MCreateProjectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onProjectCreated: PropTypes.func,
}

function ProjectWizardTab({
  name,
  value,
  activeStep,
  isValid,
  Icon,
}: {
  name: string
  value: string
  activeStep: string
  isValid: boolean
  Icon: any
}) {
  const selected = activeStep === value
  return (
    <TabsTrigger
      value={value}
      disabled={activeStep !== value && !isValid}
      className={cx(
        // Base classes
        'inline-flex h-10 w-full cursor-default items-center justify-between',
        'px-4 py-2.5',

        // Conditional background
        selected ? 'bg-stone-200 text-stone-900' : 'bg-transparent'
      )}
    >
      {/*<div className="inline-flex h-10 w-full items-center justify-start gap-3 self-stretch rounded-lg px-4 py-2.5">*/}
      <div className="flex items-center justify-start gap-3">
        <div className="relative flex size-4 items-center justify-center">
          <Icon className={cx(selected ? 'text-orange-500' : 'text-stone-500')} />
        </div>
        <div className=" shrink grow basis-0 font-['Inter'] text-sm font-medium leading-tight ">
          {name}
        </div>
      </div>

      <div className="relative flex size-4 items-center justify-center">{isValid && <Check />}</div>
      {/*</div>*/}
    </TabsTrigger>
  )
}

export type EnvironmentData = Record<string, string>

export type WizardData = {
  project: ProjectData
  repository: RepositoryData
  environment: EnvironmentData
}

export type PartialWizardData = { [k in keyof WizardData]: Partial<WizardData[k]> | null }

type CreateProjectPayload = {
  formData: {
    name: string
    projectConfiguration: ProjectData
    repoository: RepositoryData
    environment: EnvironmentData
  }
  prompt: string
  organizationId: string
  teamId: string
  usecaseFields: IterationDefaults['usecase']['fields']
}

export type CompletedSteps = {
  project: boolean
  repository: boolean
  environment: boolean
  summary: boolean
}

export type ProjectFormProps = {
  onSubmit: (data: ProjectData) => void
  initialData?: Partial<ProjectData>
  onSelectField: (field: string) => void
  organizationId: string
  teamId: string
  handleCancel: () => void
}

interface RepositoryFormProps {
  onSubmit: (data: RepositoryData) => void
  onBack: () => void // New prop for handling back navigation
  initialData?: Partial<RepositoryData>
  onSelectField?: (fieldName: keyof RepositoryData | 'githubAccount') => void
  handleCancel: () => void
}

export type EnvironmentFormProps = {
  onSubmit: (data: EnvironmentData) => void
  onBack: () => void
  initialData?: Partial<EnvironmentData>
  selectedConfigurationTemplate?: ConfigurationTemplate
  onSelectField?: (fieldName: keyof EnvironmentData) => void
  handleCancel: () => void
}

export type EnvironmentOption = {
  value: string
  label: string
}

export type SummaryFormProps = {
  onSubmit: () => void
  onBack: () => void
  formData: PartialWizardData
  submittingInProgress: boolean
  handleCancel: () => void
}

const EMPTY_DATA = {
  project: {},
  repository: {},
  environment: {},
}

const INITIAL_VALID = {
  project: false,
  repository: false,
  environment: false,
  summary: false,
}

type MCreateProjectWizardProps = {
  onCreateProject: (formData: WizardData, template: ConfigurationTemplate) => void
  submittingInProgress: boolean
  configurationTemplates: ConfigurationTemplate[]
  organizationId: string
  teamId: string
  handleCancel: () => void
}
function MCreateProjectWizard({
  onCreateProject,
  submittingInProgress,
  configurationTemplates,
  organizationId,
  teamId,
  handleCancel,
}: MCreateProjectWizardProps) {
  const [selectedField, setSelectedField] = useState<string | null>(null)

  const [activeStep, setActiveStep] = useState<WizardStep>('project')
  const [completedSteps, setCompletedSteps] = useState<CompletedSteps>(INITIAL_VALID)
  const [formData, setFormData] = useState<PartialWizardData>(EMPTY_DATA)
  useFetchGithubAuthorizationUrl() // just to trigger the fetch ahead of time
  useFetchGithubInstallationUrl()

  const selectedConfigurationTemplate = useMemo(() => {
    if (formData.project?.techStack) {
      return configurationTemplates?.find(template => template.id === formData.project?.techStack)
    }
    return null
  }, [configurationTemplates, formData.project?.techStack])

  const content = useHelpContent(activeStep, selectedField, selectedConfigurationTemplate)

  const handleStepChange = (step: WizardStep) => {
    if (!completedSteps[step] && step !== activeStep) {
      const steps: WizardStep[] = ['project', 'repository', 'environment', 'summary']
      const currentIndex = steps.indexOf(activeStep)
      const targetIndex = steps.indexOf(step)

      if (targetIndex > currentIndex && !completedSteps[steps[currentIndex]]) {
        return
      }
    }

    setActiveStep(step)
  }

  const handleProjectSubmit = async (data: ProjectData) => {
    try {
      if (formData.project?.techStack && data.techStack !== formData.project?.techStack) {
        setFormData(prev => ({ ...prev, repository: {}, environment: {} }))
      }
      // await new Promise(resolve => setTimeout(resolve, 500))
      const { organizationId, teamId } = data
      setFormData(prev => ({ ...prev, organizationId, teamId, project: data }))
      setCompletedSteps(prev => ({ ...prev, project: true }))
      setActiveStep('repository')
    } catch (error) {
      console.error('Error saving project data:', error)
    }
  }

  const handleRepositorySubmit = async (data: RepositoryData) => {
    try {
      // await new Promise(resolve => setTimeout(resolve, 500))
      setFormData(prev => ({ ...prev, repository: data }))
      setCompletedSteps(prev => ({ ...prev, repository: true }))
      setActiveStep('environment')
    } catch (error) {
      console.error('Error saving repository data:', error)
    }
  }

  const handleEnvironmentSubmit = async (data: EnvironmentData) => {
    try {
      // await new Promise(resolve => setTimeout(resolve, 500))
      setFormData(prev => ({ ...prev, environment: data }))
      setCompletedSteps(prev => ({ ...prev, environment: true }))
      setActiveStep('summary')
    } catch (error) {
      console.error('Error saving environment data:', error)
    }
  }

  const handleBuildPoc = async (prompt: string) => {
    onCreateProject(formData, selectedConfigurationTemplate, prompt)
  }

  const handleBack = (data: RepositoryData | EnvironmentData) => {
    switch (activeStep) {
      case 'repository':
        setFormData(prev => ({ ...prev, repository: data }))
        setActiveStep('project')
        break
      case 'environment':
        setFormData(prev => ({ ...prev, environment: data }))
        setActiveStep('repository')
        break
      case 'summary':
        setActiveStep('environment')
        break
    }
  }

  if (submittingInProgress) {
    return (
      <div data-testid="starting-project-splash" className="rounded-lg bg-base-muted p-4 shadow-lg">
        <div className=" grid w-full grid-cols-1 justify-items-center rounded-lg border border-base-border bg-base-card px-8 py-20 shadow-sm">
          <div className="rounded-full bg-gradient-to-b from-[rgba(149,78,226,0)] to-[rgba(149,78,226,0.2)] p-10">
            <Logo color="currentColor" className="animate-spin text-purple-500" />
          </div>
          <h3 className="mt-8 font-pp-supply-sans text-3xl leading-10 text-base-foreground">
            We’re building {formData?.project?.name} PoC
          </h3>
          <p className="text-sm tracking-tight text-base-muted-foreground">
            It shouldn’t take more than a couple of minutes...
          </p>
        </div>
      </div>
    )
  }

  return (
    <Tabs
      value={activeStep}
      // onValueChange={handleStepChange}
      className=" w-full "
      orientation="vertical"
      data-testid="create-project-wizard"
    >
      <div className="inline-flex w-full flex-col items-center justify-center gap-4 rounded-lg bg-stone-100 p-4 shadow">
        <div className="inline-flex min-h-96 items-center justify-between gap-4 self-stretch">
          <div className="inline-flex  w-[204px] flex-col items-start justify-between self-stretch pb-14">
            <div className="flex flex-col items-start justify-start gap-4 self-stretch">
              <div className="font-['Inter'] text-base font-medium leading-normal text-stone-900">
                New Project
              </div>

              <TabsList className="flex flex-col items-start justify-start gap-1 self-stretch rounded-md">
                <ProjectWizardTab
                  name="Project"
                  value="project"
                  activeStep={activeStep}
                  isValid={!!completedSteps.project}
                  Icon={FolderOpen}
                />
                <ProjectWizardTab
                  name="Repository"
                  value="repository"
                  activeStep={activeStep}
                  isValid={!!completedSteps.repository}
                  Icon={SiGithub}
                />
                <ProjectWizardTab
                  name="Environment"
                  value="environment"
                  activeStep={activeStep}
                  isValid={!!completedSteps.environment}
                  Icon={Container}
                />
                <ProjectWizardTab
                  name="Summary"
                  value="summary"
                  activeStep={activeStep}
                  isValid={!!completedSteps.summary}
                  Icon={FileText}
                />
              </TabsList>
            </div>
            <div className=" self-stretch font-['Inter'] text-sm font-normal leading-tight text-stone-500">
              {content?.text}
            </div>
          </div>
          <div className="flex-1">
            <TabsContent value="project" className="mt-0 w-full data-[state=inactive]:hidden">
              <ProjectForm
                onSubmit={handleProjectSubmit}
                initialData={formData.project}
                onSelectField={field => {
                  setSelectedField(field)
                }}
                organizationId={organizationId}
                teamId={teamId}
                handleCancel={handleCancel}
              />
            </TabsContent>
            <TabsContent value="repository" className="mt-0 w-full data-[state=inactive]:hidden">
              <RepositoryForm
                onSubmit={handleRepositorySubmit}
                onBack={handleBack}
                initialData={formData.repository}
                onSelectField={field => {
                  setSelectedField(field)
                }}
                handleCancel={handleCancel}
              />
            </TabsContent>
            <TabsContent value="environment" className="mt-0 w-full data-[state=inactive]:hidden">
              <EnvironmentForm
                onSubmit={handleEnvironmentSubmit}
                onBack={handleBack}
                initialData={formData.environment}
                onSelectField={field => {
                  setSelectedField(field)
                }}
                selectedConfigurationTemplate={selectedConfigurationTemplate}
                handleCancel={handleCancel}
              />
            </TabsContent>
            <TabsContent value="summary" className="mt-0 w-full data-[state=inactive]:hidden">
              <SummaryForm
                onSubmit={handleBuildPoc}
                onBack={handleBack}
                formData={formData}
                submittingInProgress={submittingInProgress}
                handleCancel={handleCancel}
              />
            </TabsContent>
          </div>
        </div>
      </div>
    </Tabs>
  )
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  organizationId,
  teamId,
  handleCancel,
}) => {
  const [showRequirements, setShowRequirements] = React.useState(Boolean(initialData?.requirements))
  const getTeamsByOrgId = useStore(state => state.getTeamsByOrgId)
  const organizationsOptions = useStore(state =>
    state.organizations
      .filter(org => !org.is_reference)
      .map(org => ({ value: org.id, label: org.name }))
  )
  const initialSelectedOrganizationId =
    initialData?.organizationId ??
    organizationId ??
    (organizationsOptions.length === 1 ? organizationsOptions[0].value : '')
  const [teamOptions, setTeamOptions] = useState(() =>
    getTeamsByOrgId(initialSelectedOrganizationId)
      .filter(team => !team.is_reference)
      .map(org => ({ value: org.id, label: org.name }))
  )
  const initialSelectedTeamId =
    initialData?.teamId ?? teamId ?? (teamOptions.length === 1 ? teamOptions[0].value : '')

  const showOrgAndTeamFields = organizationsOptions.length > 1 || teamOptions.length > 1

  const form = useForm<ProjectData>({
    resolver: zodResolver(projectSchema),
    defaultValues: {
      name: initialData?.name || '',
      prospectName: initialData?.prospectName || '',
      prospectWebsite: initialData?.prospectWebsite || '',
      techStack: initialData?.techStack || '',
      organizationId: initialSelectedOrganizationId,
      teamId: initialSelectedTeamId,
      requirements: initialData?.requirements || '',
    },
    mode: 'onChange',
  })

  const organizationIdField = form.watch('organizationId')
  const teamIdField = form.watch('teamId')

  useEffect(() => {
    setTeamOptions(
      getTeamsByOrgId(organizationIdField)
        .filter(team => !team.is_reference)
        .map(team => ({ value: team.id, label: team.name }))
    )
  }, [organizationIdField, getTeamsByOrgId])

  React.useEffect(() => {
    form.setFocus('name')
  }, [form.setFocus])

  const toggleRequirements = () => {
    setShowRequirements(!showRequirements)
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex w-full flex-col items-start justify-start gap-5 self-stretch overflow-y-auto rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Project details
        </div>
        <div className="flex  flex-col items-start justify-start gap-2 self-stretch">
          <label className="text-sm font-medium">Project</label>
          <Input
            {...form.register('name')}
            placeholder="Project name"
            className="my-custom-focus"
            onFocus={() => onSelectField('name')}
          />
          <FormErrorField error={form.formState.errors.name} />
        </div>

        {showOrgAndTeamFields && (
          <Controller
            name="organizationId"
            control={form.control}
            onFocus={() => onSelectField('organizationId')}
            render={({ field, fieldState: { error } }) => (
              <div className="flex flex-col items-start justify-start gap-2 self-stretch">
                <Label htmlFor="organizations" className="text-sm font-medium text-gray-700">
                  Organization
                </Label>
                <Select
                  onFocus={() => onSelectField('organizationId')}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="focus:border-black!important mt-1 h-9  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
                >
                  <SelectTrigger
                    id="organizations"
                    onClick={e => e.stopPropagation()}
                    onFocus={() => onSelectField('organizationId')}
                    // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                    tabIndex={0}
                    className="my-custom-focus mt-1"
                  >
                    <SelectValue placeholder="Select an organization..." />
                  </SelectTrigger>
                  <SelectContent className="w-full" onFocus={() => onSelectField('organizationId')}>
                    {organizationsOptions.map(option => {
                      return (
                        <SelectItem key={option.value} value={option.value}>
                          <div className="flex items-center justify-start gap-3">
                            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                              {option.label}
                            </div>
                          </div>
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>

                {error && <p className="mt-1 text-red-500">{error.message}</p>}
              </div>
            )}
          />
        )}

        {showOrgAndTeamFields && (
          <Controller
            name="teamId"
            control={form.control}
            onFocus={() => onSelectField('teamId')}
            render={({ field, fieldState: { error } }) => (
              <div className="flex flex-col items-start justify-start gap-2 self-stretch">
                <Label htmlFor="teams" className="text-sm font-medium text-gray-700">
                  Team
                </Label>
                <Select
                  onFocus={() => onSelectField('teamId')}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  className="focus:border-black!important mt-1 h-9  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
                >
                  <SelectTrigger
                    id="teams"
                    onClick={e => e.stopPropagation()}
                    onFocus={() => onSelectField('teamId')}
                    // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                    tabIndex={0}
                    className="my-custom-focus mt-1"
                  >
                    <SelectValue placeholder="Select a team..." />
                  </SelectTrigger>
                  <SelectContent className="w-full" onFocus={() => onSelectField('teamId')}>
                    {teamOptions.map(option => {
                      return (
                        <SelectItem key={option.value} value={option.value}>
                          <div className="flex items-center justify-start gap-3">
                            <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                              {option.label}
                            </div>
                          </div>
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>

                {error && <p className="mt-1 text-red-500">{error.message}</p>}
              </div>
            )}
          />
        )}

        <div className="flex flex-col items-start justify-start gap-2 self-stretch">
          <label className="text-sm font-medium">Prospect</label>
          <Input
            {...form.register('prospectName')}
            placeholder="Prospect"
            className="my-custom-focus"
            onFocus={() => onSelectField('prospectName')}
          />
          <FormErrorField error={form.formState.errors.prospectName} />
        </div>
        <div className="flex  flex-col items-start justify-start gap-2 self-stretch">
          <label className="text-sm font-medium">Prospect website</label>
          <Input
            {...form.register('prospectWebsite')}
            placeholder="https://www.example.com"
            type="text"
            className="my-custom-focus"
            onFocus={() => onSelectField('prospectWebsite')}
          />
          <FormErrorField error={form.formState.errors.prospectWebsite} />
        </div>

        <Controller
          name="techStack"
          control={form.control}
          onFocus={() => onSelectField('techStack')}
          render={({ field, fieldState: { error } }) => (
            <div className="flex h-[62px] flex-col items-start justify-start gap-2 self-stretch">
              <Label htmlFor="techStack" className="text-sm font-medium text-gray-700">
                Tech stack
              </Label>
              <Select
                onFocus={() => onSelectField('techStack')}
                onValueChange={field.onChange}
                defaultValue={field.value}
                className="focus:border-black!important mt-1  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
              >
                <SelectTrigger
                  id="techStack"
                  onFocus={() => onSelectField('techStack')}
                  // className="w-full focus:ring-2  focus:ring-ring focus:ring-offset-1"
                  tabIndex={0}
                  className="my-custom-focus mt-1"
                >
                  <SelectValue placeholder="Select a stack..." />
                </SelectTrigger>
                <SelectContent className="w-full" onFocus={() => onSelectField('technologies')}>
                  {technologiesOptions.map(option => {
                    const stack = option.value
                    const TechnologyIcon = stackTemplate[stack]?.icon
                    return (
                      <SelectItem
                        key={option.value}
                        value={option.value}
                        disabled={option.disabled}
                      >
                        <div className="flex items-center justify-start gap-3">
                          <div className="flex size-4 items-center justify-center">
                            {TechnologyIcon && <TechnologyIcon />}
                          </div>
                          <div className="font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                            {option.label}
                          </div>
                        </div>
                      </SelectItem>
                    )
                  })}
                </SelectContent>
              </Select>

              {error && <p className="text-red-500">{error.message}</p>}
            </div>
          )}
        />

        {!showRequirements ? (
          <Button
            type="button"
            variant="outline"
            onClick={toggleRequirements}
            className="self-start"
          >
            + Requirements
          </Button>
        ) : (
          <div className="space-y-2 self-stretch">
            <label className="text-sm font-medium">Additional Requirements</label>
            <Textarea
              {...form.register('requirements')}
              placeholder="Enter additional requirements..."
              className="my-custom-focus min-h-[50px]"
              onFocus={() => onSelectField('requirements')}
              rows={3}
            />
            <FormErrorField error={form.formState.errors.requirements} />
          </div>
        )}
      </div>

      <div className="flex items-center justify-between gap-2">
        <Button onClick={handleCancel} variant="ghost" className="mr-auto bg-inherit">
          Cancel
        </Button>
        <Button type="submit" disabled={!form.formState.isValid} className="min-w-24">
          Next
        </Button>
      </div>
    </form>
  )
}

const RepositoryForm: React.FC<RepositoryFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  onBack,
  handleCancel,
}) => {
  const user = useStore(state => state.user)
  const hasGithub = Boolean(user.github && Object.keys(user.github).length > 0)

  const [isConnected, setIsConnected] = useState(hasGithub)

  const form = useForm<RepositoryData>({
    resolver: zodResolver(repositorySchema),
    defaultValues: {
      repoURI: initialData?.repoURI || undefined,
      startCommitHash: initialData?.startCommitHash || undefined,
      githubUsername: initialData.githubUsername || undefined,
    },
    mode: 'onChange',
  })

  const onCancel = () => {
    setIsConnected(false)
    form.setValue('githubUsername', undefined)
    form.setValue('repoURI', undefined)
  }

  const connectGithub = useConnectGithub()
  const installGithub = useInstallGithub()

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex w-full flex-col items-start justify-start gap-6 self-stretch rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="text-lg font-medium leading-7 text-stone-900">Repository setup</div>
        {!isConnected && (
          <RepositoryConnectionChoiceMethod
            onSelect={setIsConnected}
            hasGithub={hasGithub}
            connectGithub={connectGithub}
          />
        )}
        {isConnected && !hasGithub && <AwaitingGithubConnection onCancel={onCancel} />}
        {isConnected && hasGithub && (
          <RepositoryGithubForm
            form={form}
            onSelectField={onSelectField}
            onReauth={connectGithub}
            onInstall={installGithub}
          />
        )}
      </div>

      <div className="flex items-center gap-2">
        <Button onClick={handleCancel} variant="ghost" className="mr-auto bg-inherit">
          Cancel
        </Button>
        <Button variant="ghost" onClick={() => onBack(form.getValues())} className="bg-inherit">
          <MoveLeft /> Back
        </Button>
        <Button type="submit" disabled={!form.formState.isValid} className="min-w-24">
          Next
        </Button>
      </div>
    </form>
  )
}

const RepositoryGithubForm: React.FC<{
  form: UseFormReturn<RepositoryData>
  onSelectField?: RepositoryFormProps['onSelectField']
  onReauth: () => void
  onInstall: () => void
}> = ({ form, onSelectField, onReauth, onInstall }) => {
  const user = useStore(state => state.user)
  const logins = Object.keys(user.github)

  const selectedLogin = useWatch({
    control: form.control,
    name: 'githubUsername',
    defaultValue: form.getValues('githubUsername') || logins[0],
  })

  const repos = useGithubRepos(selectedLogin)

  return (
    <>
      <div className="flex flex-col items-start justify-start gap-2 self-stretch">
        <Controller
          defaultValue={logins[0]}
          control={form.control}
          name="githubUsername"
          render={({ field }) => (
            <DropdownMenu onOpenChange={() => onSelectField('githubAccount')}>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="w-full content-start !justify-start">
                  <div className="flex items-center justify-start gap-3">
                    <div className="inline-flex gap-3 font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                      <SiGithub className="size-4" />
                      <span>
                        @{selectedLogin}
                        <span className="font-normal"> account connected</span>
                      </span>
                    </div>
                  </div>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="mt-1 w-[var(--radix-dropdown-menu-trigger-width)] rounded border border-border bg-white p-2 shadow-lg"
                align="start"
              >
                {logins
                  .filter(login => login !== selectedLogin)
                  .map(login => (
                    <DropdownMenuItem key={login} onSelect={() => field.onChange}>
                      Select @{login}
                    </DropdownMenuItem>
                  ))}
                <DropdownMenuItem onSelect={onReauth}>Add GitHub account</DropdownMenuItem>
                <DropdownMenuItem onSelect={onInstall}>Authorize repositories</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        ></Controller>
      </div>
      <div className="flex flex-col items-start justify-start gap-2 self-stretch">
        <Label htmlFor="repositories" className="text-sm font-medium">
          Pick repository
          <span className="ml-2 font-normal text-muted-foreground">
            {' '}
            Not seeing your repositories?{' '}
            <button
              id="check-permissions-btn"
              className="underline"
              onClick={onInstall}
              type="button"
            >
              Check permissions
            </button>
          </span>
        </Label>
        <Controller
          name="repoURI"
          control={form.control}
          render={({ field }) => (
            <Select
              onOpenChange={open => open && repos.refetch()}
              onValueChange={field.onChange}
              value={field.value}
              onFocus={() => onSelectField('repoURI')}
              className="focus:border-black!important mt-1  focus:ring-2 focus:ring-black focus:ring-ring focus:ring-offset-1"
            >
              <SelectTrigger
                id="repositories"
                onClick={e => e.stopPropagation()}
                onFocus={() => onSelectField('repoURI')}
                tabIndex={0}
                className="my-custom-focus mt-1"
              >
                <SelectValue placeholder="Select a repository..." />
              </SelectTrigger>
              <SelectContent className="w-full" onFocus={() => onSelectField('repoURI')}>
                {repos.isLoading && <div className="px-4 py-2 text-center text-sm">Loading...</div>}
                {repos.isError && (
                  <div className="px-4 py-2 text-center text-sm">
                    An error occured. Please refresh the page.
                  </div>
                )}
                {repos.isSuccess && repos.data.length === 0 && (
                  <div className="px-4 py-2 text-center text-sm">No repositories found.</div>
                )}
                {repos.isSuccess &&
                  repos.data.map(option => {
                    return (
                      <SelectItem key={option.clone_url} value={option.clone_url}>
                        <div className="flex items-center justify-start gap-3">
                          <div className="inline-flex gap-3 font-['Inter'] text-sm font-medium leading-tight text-stone-900">
                            <img className="size-4 rounded-full" src={option.owner.avatar_url} />
                            {option.full_name}
                          </div>
                        </div>
                      </SelectItem>
                    )
                  })}
              </SelectContent>
            </Select>
          )}
        />
      </div>
    </>
  )
}

const AwaitingGithubConnection: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
  return (
    <div className="mx-auto text-center">
      <h3 className="font-medium">Authorizing with GitHub...</h3>
      <p className="mb-2 mt-4 text-center text-muted-foreground">
        Come back to this page once the GitHub application
        <br />
        has been authorized. It will refresh automatically.
      </p>
      <Button variant="secondary" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  )
}

const RepositoryConnectionChoiceMethod: React.FC<{
  hasGithub: boolean
  connectGithub: () => void
  onSelect: (value: boolean) => void
}> = ({ hasGithub, onSelect, connectGithub }) => {
  const urlResult = useFetchGithubAuthorizationUrl()

  function onConnectGithub() {
    if (!urlResult.data) {
      throw new Error('should be only able to run if data is present')
    }
    if (!hasGithub) {
      connectGithub()
    }
    onSelect(true)
  }

  return (
    <div className="mx-auto flex max-w-sm flex-col p-4 text-center">
      <h3 className="text-lg font-medium tracking-tighter">Connect GitHub</h3>
      <p className="mb-4 mt-2 grow tracking-tight text-muted-foreground">
        Connect with all or selected GitHub repositories. Proofs automatically gets all details.
      </p>
      <Button disabled={!urlResult.isSuccess} onClick={onConnectGithub}>
        <SiGithub className="mr-2 size-4" />
        <span>{hasGithub ? 'Continue with GitHub' : 'Connect GitHub'}</span>
      </Button>
    </div>
  )
}

function useConnectGithub() {
  const urlResult = useFetchGithubAuthorizationUrl()
  const updatedAt = useStore(state => state.user.updatedAt)
  const [lastUpdatedAt, setUpdatedAt] = useState(updatedAt)
  const popup = useAuthPopup()

  const onConnectGithub = useCallback(() => {
    if (!urlResult.data) {
      throw new Error('should be only able to run if data is present')
    }
    popup.openPopup(urlResult.data)
    setUpdatedAt(updatedAt)
  }, [urlResult, updatedAt, popup.openPopup])

  useEffect(() => {
    if (popup.isOpen && lastUpdatedAt !== updatedAt) {
      // user has been updated, i.e. auth completed.
      popup.closePopup()
    }
  }, [updatedAt, lastUpdatedAt, popup.isOpen, popup.closePopup])

  return onConnectGithub
}

function useInstallGithub() {
  const urlResult = useFetchGithubInstallationUrl()
  const popup = useAuthPopup()

  const onInstallGithub = useCallback(() => {
    if (!urlResult.data) {
      throw new Error('should be only able to run if data is present')
    }
    popup.openPopup(urlResult.data)
  }, [urlResult, popup.openPopup])

  return onInstallGithub
}

function useAuthPopup() {
  const [popup, setPopup] = useState<Window | null>(null)

  const closePopup = useCallback(() => {
    if (popup && !popup.closed) {
      popup.close()
    }
  }, [popup])

  const openPopup = useCallback(
    (url: string) => {
      const popup = window.open(
        url,
        'Authorize Proofs App',
        'popup=yes,menubar=no,location=yes,width=800,height=600'
      )
      setPopup(popup)
    },
    [setPopup]
  )

  return { closePopup, openPopup, isOpen: Boolean(popup && !popup.closed) }
}

const EnvironmentForm: React.FC<EnvironmentFormProps> = ({
  onSubmit,
  initialData = {},
  onSelectField,
  onBack,
  selectedConfigurationTemplate,
  handleCancel,
}) => {
  const adminFields = useAnalystEnvFields()
  const fields = useMemo(
    () => [
      ...adminFields,
      ...(selectedConfigurationTemplate?.iterationDefaultsTemplate?.environment?.fields ?? []),
    ],
    [adminFields, selectedConfigurationTemplate?.iterationDefaultsTemplate?.environment?.fields]
  )
  const defaultValues = useMemo((): Record<string, string | boolean> => {
    return fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]:
          initialData && initialData[field.key]
            ? initialData[field.key]
            : (field.default_value ?? ''),
      }),
      {}
    )
  }, [fields, initialData])

  const form = useForm({
    defaultValues: defaultValues,
    mode: 'all',
  })

  React.useEffect(() => {
    if (fields[0]) {
      form.setFocus(fields[0].key)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex h-[576px] w-full flex-col items-start justify-start gap-8 self-stretch overflow-y-scroll rounded-lg border border-stone-200 bg-white p-8 shadow">
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Environment setup
        </div>

        {(!fields || fields?.length === 0) && (
          <div>No environment setup required for this stack.</div>
        )}

        {fields.map(field => {
          return (
            <div
              className={cx('flex flex-col items-start justify-start gap-2 self-stretch')}
              key={field.key}
            >
              <Label
                htmlFor={field.key}
                className="self-stretch font-['Inter'] text-sm font-medium leading-[14px] text-stone-900"
              >
                {field.key}
                {field.required && <span className="text-destructive"> *</span>}
              </Label>
              {field.type === 'text-long' && (
                <Textarea
                  {...form.register(field.key, {
                    required: field.required ? 'Field is required' : false,
                  })}
                  placeholder={field.placeholder}
                  className={cx(
                    'my-custom-focus',
                    field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                  )}
                  onFocus={() => onSelectField?.(field.key)}
                  invalid={!!form.formState.errors[field.key]}
                />
              )}
              {field.type === 'boolean' ? (
                <Controller
                  name={field.key}
                  control={form.control}
                  render={({ field: formField }) => (
                    <Checkbox
                      checked={formField.value === 'true'}
                      onChange={(value: boolean) => formField.onChange(value.toString())}
                      name={field.key}
                      onFocus={() => onSelectField?.(field.key)}
                      invalid={!!form.formState.errors[field.key]}
                    />
                  )}
                ></Controller>
              ) : (
                <Input
                  {...form.register(field.key, {
                    required: field.required ? 'Field is required' : false,
                  })}
                  placeholder={field.placeholder}
                  className={cx(
                    'my-custom-focus',
                    field.dontEncrypt ? '' : 'redacted-input focus:display-redacted-input'
                  )}
                  onFocus={() => onSelectField?.(field.key)}
                  invalid={!!form.formState.errors[field.key]}
                />
              )}

              <FormErrorField error={form.formState.errors[field.key]} />
            </div>
          )
        })}
      </div>

      <div className="flex items-center gap-2">
        <Button onClick={handleCancel} variant="ghost" className="mr-auto bg-inherit">
          Cancel
        </Button>
        <Button variant="ghost" onClick={() => onBack(form.getValues())} className="bg-inherit">
          <MoveLeft /> Back
        </Button>
        <Button type="submit" disabled={!form.formState.isValid} className="min-w-24">
          Next
        </Button>
      </div>
    </form>
  )
}

function SummaryForm({
  onSubmit,
  onBack,
  formData,
  submittingInProgress,
  handleCancel,
}: SummaryFormProps) {
  const isSuperAdmin = useIsUserSuperAdmin()

  const [editablePrompt, setEditablePrompt] = useState(() => constructPrompt(formData?.project))

  const StackIcon = stackTemplate[formData.project.techStack]?.icon

  return (
    <div className="flex flex-col gap-4">
      <div
        className=" scrollbar-thin scrollbar-thumb-gray-200/60 hover:scrollbar-thumb-gray-300/60 scrollbar-track-transparent inline-flex h-[576px] flex-col items-start justify-start gap-8 overflow-y-auto rounded-lg
                    border border-stone-200 bg-white p-8 shadow"
      >
        <div className="font-['Inter'] text-lg font-medium leading-7 text-stone-900">
          Project for {formData.project.prospectName}.
        </div>

        <dl className="grid w-full grid-cols-[128px,1fr] text-sm leading-tight *:border-t *:border-base-border *:py-3">
          <dt className="font-medium text-muted-foreground">Prospect:</dt>
          <dd>{formData.project.prospectName}</dd>

          <dt className="font-medium text-muted-foreground">Website:</dt>
          <dd>{formData.project.prospectWebsite}</dd>

          <dt className="border-b font-medium text-muted-foreground">Date:</dt>
          <dd className="border-b">
            {new Date().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </dd>
        </dl>

        <div className="flex flex-col items-start justify-start gap-4 self-stretch font-['Inter'] text-base font-normal text-stone-900">
          <div className="flex h-16 flex-col items-start justify-start gap-4 self-stretch">
            <div className="text-base-foreground">Technology</div>
            <p>
              <span className="inline-flex items-baseline gap-1 rounded-md bg-base-muted px-1.5">
                {StackIcon && <StackIcon className="size-4 self-center" />}
                {stackTemplate[formData.project.techStack]?.label}
              </span>{' '}
              as main e-commerce system.
            </p>
          </div>
          <div className="font-medium">Objective</div>
          {isSuperAdmin ? (
            <Textarea
              value={editablePrompt}
              onChange={e => setEditablePrompt(e.target.value)}
              className="resize-none border-none focus:outline-none"
              rows={10}
            />
          ) : (
            <div className="whitespace-pre-wrap ">{editablePrompt}</div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button onClick={handleCancel} variant="ghost" className="mr-auto bg-inherit">
          Cancel
        </Button>
        <Button variant="ghost" onClick={onBack} className="bg-inherit">
          <MoveLeft /> Back
        </Button>
        <Button
          onClick={() => onSubmit(editablePrompt)}
          className="w-full"
          disabled={submittingInProgress}
        >
          {submittingInProgress ? (
            'Building PoC...'
          ) : (
            <>
              Build <Rocket />
            </>
          )}
        </Button>
      </div>
    </div>
  )
}

function useCreateProject() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const navigate = useNavigate()

  /**
   * Create a new project.
   */
  const createFn = async ({
    organizationId,
    teamId,
    formData,
    usecaseFields,
    templateId,
    prompt,
  }: CreateProjectPayload) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)

    try {
      // pick integer from 1 to 10 randomly
      const randomImage = Math.floor(Math.random() * 10) + 1
      // Payload should look something like this:

      const payload = {
        organizationId,
        teamId,
        projectData: {
          name: formData.name,
          // description: formData.description, // this doesnt seem to exist
          projectConfiguration: formData.projectConfiguration,
          image: `${randomImage}`,
          iterationDefaults: {
            environment: formData.environment,
            repository: {
              ...formData.repoository,
              githubUsername: formData.repoository.githubUsername,
            },
            usecase: usecaseFields.reduce((acc, { key, value }) => {
              acc[key] = value
              return acc
            }, {}),
            templateId: templateId || null,
          },
        },
      }

      const response = await createProjectFirebaseFunction(payload)
      analyticsTrackEvent(ANALYTIC_EVENTS.PROJECT_CREATED, {
        organizationId,
        teamId,
        templateId,
        projectId: response?.projectId || 'N/A',
        projectName: formData.name,
      })
      const projectId = response.data.projectId
      const iterationResponse = await createIterationForProject({
        projectId,
        prompt,
        dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
        configuration: formData.projectConfiguration,
      })
      setResponse(iterationResponse)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CREATE, {
        prompt,
        projectId,
        teamId: response?.data?.teamId || 'N/A',
        organizationId: response?.data?.organizationId || 'N/A',
        iterationId: response?.data?.iterationId || 'N/A',
      })
      navigate(
        `/projects/${iterationResponse.data.projectId}?iteration=${iterationResponse.data.iterationId}`
      )
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateFn() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createFn, resetCreateFn }
}

function useFetchGithubAuthorizationUrl() {
  const user = useStore(state => state.user)
  return useQuery({
    queryKey: ['github-auth-url', user.uid],
    queryFn: async () => {
      const response = await authorizeGithub()
      return response.data.installationUrl
    },
    staleTime: 600_000,
  })
}

function useFetchGithubInstallationUrl() {
  const user = useStore(state => state.user)
  return useQuery({
    queryKey: ['github-install-url', user.uid],
    queryFn: async () => {
      const response = await installGithub()
      return response.data.installationUrl
    },
    staleTime: 3600_000,
  })
}

function useGithubRepos(login: string | undefined) {
  return useQuery({
    queryKey: ['github-repos', login],
    enabled: typeof login === 'string',
    queryFn: async () => {
      if (!login) {
        throw new Error('Could not fetch repositories: missing login.')
      }
      const response = await fetchGithubRepositories({ login })
      return response.data
    },
    staleTime: 5_000,
  })
}

function useCreateIteration() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new iteration for a project.
   * @param {Object} options - The options for the function.
   * @param {String} options.projectId - The ID of the project.
   * @param {String} options.prompt - The prompt for the iteration.

   * @returns {Promise<Object>} A promise that resolves when the iteration is created.
   */
  const createIteration = async ({
    projectId,
    prompt,
    sourceIterationId,
    configuration,
    usecase,
  }) => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)
    try {
      const response = await createIterationForProject({
        projectId,
        prompt,
        sourceIterationId,
        dontStartGunslinger: !START_GUNSLINGER_FOR_NEW_ITERATION,
        configuration,
        usecase,
      })
      setResponse(response)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CREATE, {
        prompt,
        projectId,
        teamId: response?.data?.teamId || 'N/A',
        organizationId: response?.data?.organizationId || 'N/A',
        iterationId: response?.data?.iterationId || 'N/A',
      })
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateIteration() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return { response, isCreating, error, createIterationFn: createIteration, resetCreateIteration }
}
