import PropTypes from 'prop-types'

const Logo = ({ color = 'rgb(17, 24, 39)', className = '', ...props }) => {
  return (
    <svg
      viewBox="0 0 40 40"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path d="M19.8143 39.9998C30.7512 39.9998 39.6275 31.0398 39.6275 19.9999C39.6275 8.95995 30.7512 0 19.8143 0V9.73709C25.4186 9.73709 29.9813 14.3428 29.9813 19.9999C29.9813 25.657 25.4186 30.2627 19.8143 30.2627V39.9998Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62354 18.9026V21.0969L0 21.5197V18.4797L9.62354 18.9026Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7262 30.2864H20.9L21.3189 40.0006H18.3073L18.7262 30.2864Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9 9.72566H18.7262L18.3073 0H21.3189L20.9 9.72566Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9589 10.069L14.955 10.9033L10.9244 2.05765L13.7096 0.914795L16.9589 10.069Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.97369 22.8806L10.8002 24.9035L2.03709 28.972L0.904907 26.1606L9.97369 22.8806Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3829 11.954L11.8431 13.5083L4.73303 6.93689L6.87287 4.78833L13.3829 11.954Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8474 15.0159L10.0096 17.0274L0.963501 13.6559L2.12965 10.856L10.8474 15.0159Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8431 26.4907L13.3829 28.045L6.87287 35.2221L4.73303 33.0621L11.8431 26.4907Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8665 29.051L16.8591 29.8967L13.5192 39.0281L10.7454 37.8396L14.8665 29.051Z"
      />
    </svg>
  )
}

Logo.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  // You can add more specific PropTypes for other SVG attributes if needed
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Logo
