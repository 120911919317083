import { useQuery } from '@tanstack/react-query'
import { getIteration } from '@/services/Firebase.ts'

export const useIterationQuery = (iterationId: string | null) => {
  return useQuery({
    enabled: Boolean(iterationId),
    queryKey: ['iteration', iterationId],
    queryFn: async () => {
      if (!iterationId) {
        throw new Error('iterationId is required')
      }
      const result = await getIteration({ iterationId })
      return result.data
    },
  })
}
