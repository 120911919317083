import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverClose,
  PopoverArrow,
} from '@/components/ui/popover'

import { XMarkIcon } from '@heroicons/react/16/solid'
import { Button } from '@/components/catalyst/button'

import { stopVMJobForIterationFirebaseFunction } from '@/services/Firebase'
import { GUNSLINGER_JOB_STATUSES } from '@/const/const.ts'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

function allowToKillVMForStatus(status) {
  return status === GUNSLINGER_JOB_STATUSES.RUNNING
}

export default function MStopVMButton({ iterationId, status, onVMStopped = () => {} }) {
  const [isWorking, setIsWorking] = useState(false)
  const [isDone, setIsDone] = useState(false)

  const handleStopVM = useCallback(async () => {
    setIsWorking(true)
    try {
      await stopVMJobForIterationFirebaseFunction({ iterationId })
      onVMStopped(iterationId)
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_STOP_VM, { iterationId })
    } catch (error) {
      console.error('Error stopping VM', error)
    } finally {
      setIsWorking(false)
      setIsDone(true)
    }
  }, [iterationId, onVMStopped])

  if (!allowToKillVMForStatus(status)) {
    return null
  }

  if (isDone) {
    return null
  }

  return (
    <div className="flex-1">
      <Popover defaultOpen={false}>
        <PopoverTrigger asChild>
          <Button disabled={isWorking} outline>
            {isWorking ? 'Stopping...' : 'Stop'}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <div>
            <div className="mb-4 flex flex-row justify-between">
              <h5 className="text-md font-semibold text-zinc-700">Are you sure? 🧨</h5>
              <PopoverClose aria-label="Cancel">
                <XMarkIcon className="size-5" />
              </PopoverClose>
            </div>
            <p className="text-sm text-zinc-500">
              Do you want to stop the VM?{' '}
              <span className="block font-semibold">The iteration will not continue.</span>
            </p>
            <div className="mt-6 flex justify-end">
              <PopoverClose asChild aria-label="Cancel delete">
                <Button disabled={isWorking} outline className="">
                  Cancel
                </Button>
              </PopoverClose>
              <Button disabled={isWorking} color="red" onClick={handleStopVM} className="ml-2">
                {isWorking ? 'Working...' : 'Yes, stop the VM'}
              </Button>
            </div>
          </div>
          <PopoverArrow />
        </PopoverContent>
      </Popover>
    </div>
  )
}

MStopVMButton.propTypes = {
  iterationId: PropTypes.string,
  status: PropTypes.string,
  onVMStopped: PropTypes.func,
}
