import ReactMarkdown from 'react-markdown'
import rehypeSanitize from 'rehype-sanitize'
import { useMemo } from 'react'

const defaultComponents = {
  h1: ({ node, ...props }) => (
    <h1 className="my-4 text-2xl font-medium leading-7 text-stone-900" {...props} />
  ),
  h2: ({ node, ...props }) => (
    <h2 className="my-3 text-xl  font-medium leading-7 text-stone-900" {...props} />
  ),
  h3: ({ node, ...props }) => <h3 className="my-2 text-lg font-medium text-stone-600" {...props} />,
  ul: ({ node, ...props }) => <ul className="list-disc space-y-2 pl-5 text-stone-800" {...props} />,
  li: ({ node, ...props }) => (
    <li className=" text-sm font-normal leading-tight text-stone-500" {...props} />
  ),
  p: ({ node, ...props }) => (
    <p className="my-2  text-sm font-normal leading-tight text-stone-500" {...props} />
  ),
  pre: ({ node, ...props }) => (
    <pre
      className="font-['DM Mono'] overflow-x-auto whitespace-pre-wrap text-sm font-normal leading-tight text-stone-50"
      {...props}
    />
  ),
}

const MarkdownContent = ({ detailsContent, components: customComponents = {} }) => {
  // Default components

  // Merge default and custom components
  const mergedComponents = useMemo(
    () => ({ ...defaultComponents, ...customComponents }),
    [customComponents]
  )

  return (
    <div className="prose w-full max-w-full font-['Inter']">
      <ReactMarkdown
        className="[&_pre]:!overflow-x-auto [&_pre]:whitespace-pre-wrap"
        rehypePlugins={[rehypeSanitize]}
        components={mergedComponents}
      >
        {detailsContent}
      </ReactMarkdown>
    </div>
  )
}

export { MarkdownContent }
