import { useState } from 'react'
import { Input } from '@/components/ui/input.tsx'
import { Button } from '@/components/ui/button.tsx'
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { auth } from '@/services/Firebase.ts'

export default function PRobotLogin() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [signIn, _user, signingIn, error] = useSignInWithEmailAndPassword(auth)
  const onSubmit = e => {
    e.preventDefault()
    signIn(email, password)
  }

  return (
    <div className="h-screen bg-base-muted p-6">
      <form
        onSubmit={onSubmit}
        className="mx-auto mt-40 max-w-lg justify-self-center rounded border border-border bg-white p-4"
      >
        <h1 className="mb-10 text-2xl">Beep boop</h1>

        <div className="space-y-3">
          <Input
            name="email"
            onInput={e => setEmail(e.currentTarget.value)}
            type="email"
            placeholder="email"
          />
          <Input
            name="password"
            onInput={e => setPassword(e.currentTarget.value)}
            type="password"
            placeholder="password"
          />

          <Button type="submit" disabled={signingIn}>
            Login
          </Button>

          {error && <p className="mt-4 text-red-500">{error.message}</p>}
        </div>
      </form>
    </div>
  )
}
