import { Dialog, DialogOverlay } from '@/components/ui/dialog'
import { useEffect, useState } from 'react'

export default function MDesktopRestriction() {
  const isMobile = useMedia(`(max-width: ${MAX_MOBILE_WIDTH}px)`)

  return (
    <Dialog open={isMobile}>
      <DialogOverlay className="bg-white">
        <div className="mx-auto mt-20 p-4 text-secondary-foreground sm:p-8">
          <h2 className="text-3xl text-primary">Heads up!</h2>
          <p className="mt-4">
            For optimal performance and viewing experience, please access Proofs using a desktop
            device.
          </p>

          <p className="mt-2">
            We apologize for any inconvenience - our mobile version is currently under development
            and will be available soon.
          </p>
        </div>
      </DialogOverlay>
    </Dialog>
  )
}

function useMedia(media: string) {
  const [matches, setMatches] = useState(false)
  useEffect(() => {
    if (!globalThis.window) return
    const match = window.matchMedia(media)
    setMatches(match.matches)
    const listener = () => setMatches(match.matches)
    match.addEventListener('change', listener)
    return () => match.removeEventListener('change', listener)
  }, [setMatches, media])

  return matches
}

const MAX_MOBILE_WIDTH = 1060
