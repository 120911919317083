import { MarkdownContent } from '@/components/actions/components/MarkdownContent.tsx'
import { Button } from '@/components/ui/button.tsx'
import { CircleUser } from 'lucide-react'
import { useState } from 'react'

type Props = {
  specifications: string
  prompts: string[]
}

export default function SpecificationPreview({ specifications, prompts }: Props) {
  const [showsInitialPrompt, setShowsInitialPrompt] = useState(false)

  return (
    <div>
      <div className="bg-white p-8">
        <MarkdownContent detailsContent={specifications} />
        {prompts.length > 0 && (
          <Button variant="secondary" onClick={() => setShowsInitialPrompt(!showsInitialPrompt)}>
            {showsInitialPrompt ? 'Hide' : 'See'} Initial Prompt
          </Button>
        )}
      </div>
      {showsInitialPrompt && (
        <ul className="space-y-5 border-t border-border bg-base-muted px-8 py-6">
          {prompts.map((prompt, idx) => (
            <li className="text-sm text-muted-foreground" key={prompt + idx}>
              <h4 className="flex flex-row items-center">
                <CircleUser className="mr-2 size-4" />
                <span className="text-foreground">User Instructions</span>
              </h4>
              <p>{prompt}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
