import { useState, useEffect } from 'react'
import squareLogo from '@/assets/images/logo.svg'
import textLogo from '@/assets/images/logo-text.svg'
import dots from '@/assets/images/dots.svg'
import alokaiLogo from '@/assets/images/alokai-logo.svg'
import alokaiFilipR from '@/assets/images/alokai-filip-r.png'
import { motion, AnimatePresence } from 'framer-motion'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { EnterEmailStep } from './EnterEmailStep'
import { EmailLinkSentStep } from './EmailLinkSentStep'
import { sendMagicLink } from '@/services/Firebase.ts'
import { useMutation } from '@tanstack/react-query'

const REFERENCES = [
  {
    logo: alokaiLogo,
    text: (
      <>
        Proofs&apos; AI code-gen tools are a game-changer for us in pre-sales. They&apos;ve made
        sales pitches much easier by enhancing our sales team&apos;s ability to showcase product
        value.
      </>
    ),
    name: 'Filip Rakowski',
    title: 'Co-Founder',
    company: 'Alokai (YC Alumni)',
    avatar: alokaiFilipR,
  },
  // Add more references here
]

export default function PLogin() {
  const [email, setEmail] = useState<string | null>(null)
  const [currentReferenceIndex, setCurrentReferenceIndex] = useState(0)
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const STEPS = [
    {
      Component: EnterEmailStep,
    },
    {
      Component: EmailLinkSentStep,
    },
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReferenceIndex(prevIndex => (prevIndex + 1) % REFERENCES.length)
    }, 5000) // Change reference every 5 seconds

    return () => clearInterval(interval)
  }, [])

  const CurrentStepComponent = STEPS[currentStepIndex].Component

  return (
    <div className="grid h-screen grid-cols-2 bg-base-muted p-6">
      <div className="min-h-full grid-cols-1">
        <div className="mx-auto flex h-full w-full max-w-96 flex-col items-center justify-center">
          <div className="left-20 top-20 mx-auto flex w-full items-center justify-between">
            <img className="h-6 w-auto" src={textLogo} alt="Mimir text logo" />
            <img className="h-6 w-auto" src={squareLogo} alt="Mimir logo" />
          </div>
          <div className="flex flex-1 flex-col items-center justify-center">
            <CurrentStepComponent
              email={email}
              setEmail={setEmail}
              onNext={() => setCurrentStepIndex(prevIndex => prevIndex + 1)}
            />
          </div>
          <div className="text-sm text-base-muted-foreground">
            © {new Date().getFullYear()} Proofs, Inc. All rights reserved.
          </div>
        </div>
      </div>
      <div className="flex min-h-full grid-cols-1 flex-col">
        <div className="relative flex-1 overflow-hidden rounded-t-md">
          <img src={dots} alt="dots" />
        </div>
        <div className="space-y-8">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentReferenceIndex}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
              className="space-y-8"
            >
              <img
                src={REFERENCES[currentReferenceIndex].logo}
                alt={`${REFERENCES[currentReferenceIndex].company} logo`}
                className="h-6 w-auto"
              />
              <div className="text-base-foreground">{REFERENCES[currentReferenceIndex].text}</div>
              <div className="flex items-center gap-2">
                <Avatar className="size-12">
                  <AvatarImage src={REFERENCES[currentReferenceIndex].avatar} />
                  <AvatarFallback>{REFERENCES[currentReferenceIndex].name[0]}</AvatarFallback>
                </Avatar>
                <div className="spacey-y-2">
                  <div className="font-medium text-base-foreground">
                    {REFERENCES[currentReferenceIndex].name}
                  </div>
                  <div className="text-base-muted-foreground">
                    {REFERENCES[currentReferenceIndex].title} at{' '}
                    {REFERENCES[currentReferenceIndex].company}
                  </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
          <div className="mt-4 flex space-x-2">
            {REFERENCES.map((_, index) => (
              <div
                key={index}
                className={`h-1 w-8 rounded-full ${
                  index === currentReferenceIndex ? 'bg-gradient-orange' : 'bg-stone-400'
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
