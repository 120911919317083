import { ACL_ROLES } from '@/const/const.ts'

/**
 * Check if user is Superadmin
 * @param {*} roles - User roles object
 * @returns {boolean} - True if user is Superadmin, false otherwise
 */
export function isSuperadmin(roles) {
  return roles[ACL_ROLES.SUPERADMIN] ? true : false
}
