import GenericAction from '@/components/actions/GenericAction.jsx'
import { Globe, Copy, ArrowUpRight, DownloadCloud } from 'lucide-react'
import { Button } from '@/components/ui/button.tsx'
import React, { useCallback } from 'react'
import { useToast } from '@/components/ui/use-toast'

export default function BrowserAction({
  action,
  isLast = false,
}: {
  action: BrowserActionPayload
  isLast?: boolean
}) {
  const comment = action.args.comment
  const url = action.args.url
  const output = (action.outputs ?? []).find(isSuccessOutput)
  const isDone = action.status === 'DONE'
  const hasImage = Boolean(output?.screenshot_public_url_large)

  return (
    <GenericAction
      title={comment ?? 'Browser testing'}
      action={action}
      detailsContent={comment}
      actionIcon={Globe}
      isLast={isLast}
    >
      <BrowserWindow>
        <AddressBar url={url} />
        {isDone && output && hasImage && (
          <img src={output.screenshot_public_url_large} alt={`Screenshot of ${url}`} />
        )}
      </BrowserWindow>
      {isDone && output && hasImage && (
        <Button variant="secondary" size="sm" className="mt-2.5 w-full" asChild>
          <a href={output.screenshot_public_url_original} target="_blank" rel="noreferrer">
            <DownloadCloud className="size-4" />
            Download Screenshot
          </a>
        </Button>
      )}
    </GenericAction>
  )
}

const BrowserWindow: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="divide-y divide-border overflow-hidden rounded border border-border">
      {children}
    </div>
  )
}

const AddressBar: React.FC<{ url: string }> = ({ url }) => {
  const toast = useToast()
  const copyUrl = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(url)
      toast.toast({ title: 'URL copied to clipboard' })
    } catch (_err) {
      // no access to clipboard
      toast.toast({ title: 'Could not copy the URL', variant: 'destructive' })
    }
  }, [url])

  return (
    <div className="grid grid-cols-[max-content,1fr,max-content] place-items-center gap-2 bg-base-muted p-1 text-muted-foreground">
      <div className="ml-3 flex gap-1 *:size-1.5 *:rounded-full *:bg-border">
        <span />
        <span />
        <span />
      </div>
      <p className="overflow-hidden text-ellipsis whitespace-nowrap text-nowrap font-mono">{url}</p>
      <div>
        <Button size="icon" variant="ghost" asChild>
          <a href={url} target="_blank">
            <ArrowUpRight className="size-3" />
          </a>
        </Button>
        <Button size="icon" variant="ghost" onClick={copyUrl}>
          <Copy className="size-3" />
        </Button>
      </div>
    </div>
  )
}

type BrowserActionPayload = {
  actionId: string
  endCommitHash: string | null
  args: {
    url: string
    comment: string
  }
  startCommitHash: string | null
  elementId: string
  stepId: string
  startSnapshotHash: string | null
  name: string
  subtype: string | null
  index: number
  iterationId: string
  hideArgsFromPrompt: boolean
  id: string
  endSnapshotHash: string | null
  type: string
  status: string
  outputs: GenerateImageActionOutput[]
}

type FailedOutput = {
  error: unknown
  image_path: null
  screenshot_public_url_large?: null
  screenshot_public_url_small?: null
  screenshot_public_url_original?: null
}

type SuccessOutput = {
  success: true
  error: null
  image_path: string
  screenshot_public_url_large: string
  screenshot_public_url_small: string
  screenshot_public_url_original: string
}

type GenerateImageActionOutput = FailedOutput | SuccessOutput

function isSuccessOutput(output: GenerateImageActionOutput): output is SuccessOutput {
  return output.error === null
}
