import { useMemo } from 'react'
import { GetIterationOutput } from '@/services/firebase_shared_types.ts'
import { useIterationQuery } from './useIterationQuery.ts'

// return active role of an iteration based on its status and name
export const useIterationActiveRole = (iterationId: string | null) => {
  const { data: iteration } = useIterationQuery(iterationId)

  const [rolename, role] = getActiveRole(iteration ?? null)

  return useMemo(() => {
    return [rolename, role] as const
  }, [rolename ?? '', typeof role?.status !== 'undefined' ? role.status : ''])
}

function getActiveRole(iteration: GetIterationOutput | null) {
  const registry = iteration?.role_registry ?? {}
  const keys = Object.keys(registry)
  const rolename = keys.find(name => registry[name].status !== 'done') ?? keys[0]
  if (!rolename) {
    return [null, null] as const
  }

  return [rolename, registry[rolename]] as const
}
