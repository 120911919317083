import classNames from 'classnames'

export default function AActionPrompt({
  content,
  className,
}: {
  content: string
  className?: string
}) {
  return (
    <div
      className={classNames(
        'space-y-1 px-4 py-3',
        'bg-white',
        'rounded-md ring-1 ring-base-border',
        className
      )}
    >
      <p className="font-dm-mono text-xs">Prompt:</p>
      <p className="text-sm text-muted-foreground">{content}</p>
    </div>
  )
}
