import type { ComponentProps } from 'react'

const LogoText = ({ className = '', ...props }: ComponentProps<'svg'>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="24"
      viewBox="0 0 70 24"
      fill="none"
      className={className}
      {...props}
    >
      <g id="Vector">
        <path
          d="M13.1609 11.9893C13.1609 15.8542 10.3965 18.6674 6.80008 18.6674C4.81885 18.6674 3.15489 17.811 2.24969 16.3445H2.03004V24.001H0V5.826H2.03004V7.70961H2.24969C3.15489 6.21647 4.74564 5.33567 6.77568 5.33567C10.3721 5.33567 13.1609 8.12449 13.1609 11.9893ZM1.95683 11.9893C1.95683 14.7049 3.88925 16.8326 6.55604 16.8326C9.22283 16.8326 11.1308 14.7049 11.1308 11.9893C11.1308 9.27373 9.19842 7.17048 6.55604 7.17048C3.91365 7.17048 1.95683 9.27373 1.95683 11.9893Z"
          fill="#2E1121"
        />
        <path
          d="M15.0688 18.1793V5.826H17.0501V8.56822H17.2697C17.3407 5.83487 19.2021 5.58194 20.5733 5.58194C21.1013 5.58194 21.7225 5.70396 22.0908 5.8992V7.78283C21.4541 7.51437 20.7219 7.41675 20.256 7.41675C18.3968 7.41675 17.1011 9.08072 17.1011 11.5256V18.1793H15.0688Z"
          fill="#2E1121"
        />
        <path
          d="M22.3348 11.9893C22.3348 8.10008 25.0748 5.33567 28.9641 5.33567C32.8533 5.33567 35.5933 8.10008 35.5933 11.9893C35.5933 15.8786 32.8289 18.6674 28.9641 18.6674C25.0992 18.6674 22.3348 15.903 22.3348 11.9893ZM33.5633 11.9893C33.5633 9.20052 31.6309 7.17048 28.9641 7.17048C26.2973 7.17048 24.3649 9.20052 24.3649 11.9893C24.3649 14.7781 26.2729 16.8326 28.9641 16.8326C31.6553 16.8326 33.5633 14.8025 33.5633 11.9893Z"
          fill="#2E1121"
        />
        <path
          d="M36.8159 11.9893C36.8159 8.10008 39.5559 5.33567 43.4452 5.33567C47.3344 5.33567 50.0744 8.10008 50.0744 11.9893C50.0744 15.8786 47.31 18.6674 43.4452 18.6674C39.5803 18.6674 36.8159 15.903 36.8159 11.9893ZM48.0444 11.9893C48.0444 9.20052 46.1119 7.17048 43.4452 7.17048C40.7784 7.17048 38.846 9.20052 38.846 11.9893C38.846 14.7781 40.754 16.8326 43.4452 16.8326C46.1364 16.8326 48.0444 14.8025 48.0444 11.9893Z"
          fill="#2E1121"
        />
        <path
          d="M58.6139 13.995H60.5707C60.5951 15.7566 61.964 16.9546 64.2892 16.9546C66.1484 16.9546 67.3464 16.1714 67.3464 14.8758C67.3464 13.2606 65.658 13.1386 63.7988 12.8945C61.5003 12.6261 58.8335 12.0137 58.8335 9.20052C58.8335 6.80218 60.8392 5.33567 63.7988 5.33567C66.7585 5.33567 68.986 6.97523 68.986 9.51778H67.0048C66.9803 8.00024 65.6847 7.04624 63.7766 7.04624C61.9906 7.04624 60.7682 7.85382 60.7682 9.12509C60.7682 10.667 62.4322 10.7891 64.267 11.0087C66.5899 11.3016 69.2567 11.8895 69.2567 14.8491C69.2567 17.2719 67.373 18.6652 64.2181 18.6652C60.6706 18.6652 58.6161 16.928 58.6161 13.9928L58.6139 13.995Z"
          fill="#2E1121"
        />
        <path
          d="M54.6004 5.82599V3.82034C54.6004 2.70216 55.1217 1.89014 56.1978 1.8502C56.6504 1.83245 57.3071 1.98555 57.8285 2.20519V0.32158C57.4624 0.126341 56.7635 0.0198349 56.2377 0.0043045C56.1157 -0.00013275 55.9936 -0.00013275 55.8716 0.0043045C55.459 0.0198349 55.0751 0.0708692 54.7224 0.190675C54.3896 0.294951 54.0723 0.454687 53.7994 0.698736C53.0296 1.32439 52.5681 2.33831 52.5681 3.6229V5.82377H51.0861V7.43892H52.5681V18.1771H54.5981V7.43892H57.8285V5.82599H54.5981H54.6004Z"
          fill="#2E1121"
        />
      </g>
    </svg>
  )
}

export default LogoText
